<template>
  <div>
    <b-dropdown
      variant="link"
      toggle-class="p-0"
      no-caret
      dropup
    >
      <template #button-content>
        <slot name="button-content" />
      </template>

      <b-dropdown-form
        class="emoji-form"
        form-class="p-0"
        @submit.prevent
      >
        <div class="emoji-form-content">
          <div
            class="emoji-picker"
            :class="isDark ? 'emoji-picker-dark' : 'emoji-picker-light'"
          >
            <div
              v-for="(emojiGroup, category) in emojisList"
              :key="category"
            >
              <h5> {{ category }}</h5>
              <div class="emojis">
                <span
                  v-for="(emoji, emojiName) in emojiGroup"
                  :key="emojiName"
                  @click="selectionEmoji(emoji)"
                >
                  <!-- v-b-tooltip.hover
                  :title="emojiName" -->
                  {{ emoji }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-dropdown-form>

    </b-dropdown>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { VBTooltip, BDropdown, BDropdownForm } from 'bootstrap-vue'
import emojiIcons from './emojis'
// vue-emoji-picker/src/emojis
// const escapeRegExp = s => s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

export default {
  components: {
    BDropdown,
    BDropdownForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      emojiIcons,
    }
  },
  computed: {
    emojisList() {
      return this.emojiIcons
    },
  },
  methods: {
    // (選擇)Emoji
    selectionEmoji(emoji) {
      this.$emit('select-emoji', emoji)
    },
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return {
      isDark,
    }
  },
}
</script>

<style lang="scss" scoped>
.emoji-form {
  position: relative;
  max-width: 300px;
  height: 300px;
  .emoji-form-content {
    overflow: scroll;
    max-height: 300px;
    min-width: 280px;
  }
}

.emoji-picker {
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;

  h5 {
    margin-bottom: 5px;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: default;
  }

  .emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5px;
    &:after {
      content: "";
      flex: auto;
    }

    span {
      font-size: 20px;
      padding: 0.2rem;
      cursor: pointer;
      border-radius: 5px;
    }
  }
}

.emoji-picker-dark {
  .emojis {
    span:hover {
      background: #161d31;
      cursor: pointer;
    }
  }
}

.emoji-picker-light {
  .emojis {
    span:hover {
      background: #ececec;
      cursor: pointer;
    }
  }
}
</style>
