import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'
// import router from '@/router'

export const useOpenAISetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  // const {
  //   useHttpCodeAlert,
  // } = useAlert()

  const statusOptions = [{
    label: '停用', value: false,
  }, {
    label: '啟用', value: true,
  }]

  const ui = {
    statusList: [{
      false: '停用', true: '啟用',
    }, {
      false: 'light-secondary', true: 'light-success',
    }],
    onlineStatus: [{
      online: '在線', busy: '忙線', offline: '離線',
    }, {
      online: 'dot-green', busy: 'dot-yellow', offline: 'dot-gray',
    }],
  }

  return {
    ui,
    statusOptions,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  }
}

export const useOpenAIList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: 'AI機器人名稱', key: 'role_name', sortable: true, searchable: true, select: true,
    },
    {
      label: 'AI角色設定', key: 'setting', sortable: false, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '更新日期', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  sortBy.value = 'id'

  const isBusy = ref(false)
  const timeRange = ref(null)

  const setOpenAIDelete = (...arg) => store.dispatch('admin-openAI/setOpenAIDelete', ...arg)
  const setOpenAICreate = (...arg) => store.dispatch('admin-openAI/setOpenAICreate', ...arg)
  const setOpenAIUpdate = (...arg) => store.dispatch('admin-openAI/setOpenAIUpdate', ...arg)
  const setOpenAIImageUpdate = (...arg) => store.dispatch('admin-openAI/setOpenAIImageUpdate', ...arg)
  const setOpenAIImageDelete = (...arg) => store.dispatch('admin-openAI/setOpenAIImageDelete', ...arg)

  const getOpenAIListData = (ctx, callback) => {
    // 整理timeRange
    let resolveTimeRange = null
    if (timeRange.value) {
      resolveTimeRange = timeRange.value.split(' 至 ')
      const [start, end] = resolveTimeRange
      if (end) {
        resolveTimeRange = `${start} to ${end}`
      } else resolveTimeRange = `${start}`
    }

    store.dispatch('admin-openAI/getOpenAIList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: resolveTimeRange,
      _: Date.now(),
    })
      .then(response => {
        const { data } = response.data
        callback(data)
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
    refetchTable,

    getOpenAIListData,
    setOpenAIDelete,
    setOpenAICreate,
    setOpenAIUpdate,
    setOpenAIImageUpdate,
    setOpenAIImageDelete,

    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useOpenAIRoomList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
    syncObjectDeep,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '房間名稱', key: 'room_name', sortable: true, searchable: true, select: true,
    },
    {
      label: '狀態', key: 'status', sortable: false, searchable: true, select: true,
    },
    {
      label: '創建人ID', key: 'user_id', sortable: false, searchable: true, select: true,
    },
    {
      label: '訊息數量', key: 'message_total', sortable: false, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '更新日期', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  perPage.value = 15
  sortBy.value = 'updated_at'

  const timeRange = ref(null)
  const isBusy = ref(false)
  const isBusyLoading = ref(false)

  const blankRoomData = {
    id: null,
    room_name: null,
    status: null,
    role_id: null,
    user_id: null,
    message_total: 0,
    role_info: {
      id: null,
      role_name: null,
      image: null,
      setting: null,
    },
    created_at: null,
    updated_at: null,
  }

  const selectedList = ref([])
  const openAIRoomData = ref([])

  const setOpenAIRoomUpdate = (...arg) => store.dispatch('admin-openAI/setOpenAIRoomUpdate', ...arg)
  const setOpenAIRoomDelete = (...arg) => store.dispatch('admin-openAI/setOpenAIRoomDelete', ...arg)

  // (初始化)聊天列表
  const getOpenAIRoomListData = () => {
    // 整理timeRange
    let resolveTimeRange = null
    if (timeRange.value) {
      resolveTimeRange = timeRange.value.split(' 至 ')
      const [start, end] = resolveTimeRange
      if (end) {
        resolveTimeRange = `${start} to ${end}`
      } else resolveTimeRange = `${start}`
    }

    store.dispatch('admin-openAI/getOpenAIRoomList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: resolveTimeRange,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          // const resolve = {
          //   ...syncObject(blankRoomData, item),
          // }
          const resolve = syncObjectDeep(blankRoomData, item)
          return resolve
        })
        openAIRoomData.value = resolveData
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  // (獲取)下一頁資料
  const getOpenAIRoomListNextData = () => {
    if (openAIRoomData.value.length > 0) isBusyLoading.value = true

    // 整理timeRange
    let resolveTimeRange = null
    if (timeRange.value) {
      resolveTimeRange = timeRange.value.split(' 至 ')
      const [start, end] = resolveTimeRange
      if (end) {
        resolveTimeRange = `${start} to ${end}`
      } else resolveTimeRange = `${start}`
    }

    const filterUrl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
    }

    store.dispatch('admin-openAI/getOpenAIRoomList', filterUrl)
      .then(response => {
        isBusy.value = false
        isBusyLoading.value = false
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          // const resolve = {
          //   ...syncObject(blankRoomData, item),
          // }
          const resolve = syncObjectDeep(blankRoomData, item)
          return resolve
        })
        resolveData.forEach(el => {
          openAIRoomData.value.push(el)
        })
        totalNum.value = total
      })
  }

  const refetchData = () => getOpenAIRoomListData()

  watch([perPage, searchQuery, timeRange], () => {
    currentPage.value = 1
    selectedList.value = []
    refetchData()
  })

  watch([currentPage], () => {
    if (currentPage.value === 1) return
    getOpenAIRoomListNextData()
  })

  return {
    isBusy,
    isBusyLoading,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
    syncObject,
    blankRoomData,

    openAIRoomData,
    selectedList,

    setOpenAIRoomUpdate,
    setOpenAIRoomDelete,
    getOpenAIRoomListData,
    getOpenAIRoomListNextData,

    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useRoomMessage = () => {
  const randomIdArray = ref([])

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useOpenAISetting()

  // 產生亂數雜錯
  const generateRandomString = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let randomString = ''

    do {
      for (let i = 0; i < 20; i += 1) {
        const randomIndex = Math.floor(Math.random() * characters.length)
        randomString += characters[randomIndex]
      }
    } while (randomIdArray.value.includes(randomString))

    randomIdArray.value.push(randomString)
    return randomString
  }

  const removeRandomIdArray = rId => {
    randomIdArray.value = randomIdArray.value.filter(item => item !== rId)
  }

  // const {
  //   blankRoomData,
  // } = useOpenAIRoomList()

  const blankRoomData = {
    id: null,
    room_name: null,
    status: null,
    role_id: null,
    user_id: null,
    message_total: 0,
    created_at: null,
    updated_at: null,
  }

  const blankRoleInfoData = {
    id: null,
    role_name: null,
    image: null,
    setting: null,
  }

  const blankMessageData = {
    rid: null,
    id: null,
    room_id: null,
    role: null,
    // role_id: null,
    // role_info: null,
    user: null,
    user_id: null,
    total_token: 0,
    message: null,

    created_at: null,
    updated_at: null,
  }

  const blankUserData = {
    name: null,
    image: null,
    online_status: null,
  }

  const openAIRoomMessageData = ref(null)
  const isMessageBusy = ref(false)
  const messageText = ref(null)

  const isSameDay = (N1, N2) => {
    const date1 = new Date(N1)
    const date2 = new Date(N2)
    const state = date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()
    return state
  }

  const getOpenAIMessage = (...arg) => store.dispatch('admin-openAI/getOpenAIMessage', ...arg)
  const setOpenAIMessageCreate = (...arg) => store.dispatch('admin-openAI/setOpenAIMessageCreate', ...arg)

  const setOpenAIRoomCreate = (...arg) => store.dispatch('admin-openAI/setOpenAIRoomCreate', ...arg)

  const resolveRoomInfoData = roomData => {
    const resolveData = {
      data: {
        ...syncObject(blankRoomData, roomData),
        role_info: roomData.role_id && roomData.role_info ? syncObject(blankRoleInfoData, roomData.role_info) : blankRoleInfoData,
      },
    }
    return resolveData
  }

  const resolveMessageInfoData = message => {
    const resolveData = {
      // rid: generateRandomString(),
      ...syncObject(blankMessageData, message),
    }
    if (message.user_id) {
      resolveData.user = syncObject(blankUserData, message.user ? message.user : {})
    }
    return resolveData
  }

  const resolveMessageListData = response => {
    const { logs } = response.data.data
    // .reverse()
    const resolveData = logs.map(item => resolveMessageInfoData(item))
    return resolveData
  }

  // const resolveRemarkInfoData = remark => {
  //   const resolveData = {
  //     // rid: generateRandomString(),
  //     ...syncObject(blankRemarkData, remark),
  //   }
  //   resolveData.body = syncObject(blankBodyData, resolveData.body)
  //   let images = []
  //   if (resolveData.body && resolveData.body.images) {
  //     images = resolveData.body.images.map(item => {
  //       const resolveImage = syncObject(blankImageData, item)
  //       return resolveImage
  //     })
  //   }
  //   resolveData.body.images = images

  //   let appends = []
  //   if (remark.appends) {
  //     appends = remark.appends.map(item => {
  //       const resolveAppends = syncObject(blankAppendData, item)
  //       return resolveAppends
  //     })
  //   }
  //   resolveData.appends = appends
  //   resolveData.user = syncObject(blankUserData, remark.user ? remark.user : {})
  //   return resolveData
  // }

  return {
    isSameDay,
    isMessageBusy,
    messageText,
    blankRoomData,
    blankMessageData,
    openAIRoomMessageData,

    getOpenAIMessage,
    setOpenAIMessageCreate,
    setOpenAIRoomCreate,

    resolveRoomInfoData,
    resolveMessageInfoData,
    resolveMessageListData,

    useAlertToast,
    useHttpCodeAlert,

    generateRandomString,
    removeRandomIdArray,
  }
}
