<template>
  <div class="chats">
    <div ref="uploadCardContent">
      <div
        ref="uploadCard"
        no-body
        class="image-explorer-contain upload-card"
        @dragenter="dragEnter"
        @dragover="preventDefault"
        @dragleave="dragLeave"
        @drop="dropFile"
      >
        <div
          ref="dropArea"
          class="dropArea hidden"
        >
          <div class="upload-label">
            <span class="label-title">
              把檔案拖放到這裡
            </span>
            <b-img
              :src="require('@/assets/images/icons/image-processing.png')"
              fluid
            />
          </div>
        </div>

        <div>
          <!-- 訊息 -->
          <div
            v-if="!isMessageLoading"
            class="msg-list animate__animated animate__fadeIn"
          >
            <!-- <span>{{ formattedChatData }}</span> -->
            <template v-if="formattedChatData.length > 0">
              <div
                v-for="(msgData) in formattedChatData"
                :id="`message-${msgData.id}`"
                :key="msgData.id"
                class="chat"
                :class="{'chat-left': msgData.userId}"
              >
                <!-- 日期 -->
                <div
                  v-if="msgData.showTime"
                  class="w-100 text-center"
                >
                  {{ moment(msgData.time).format('MM/DD') }}
                </div>

                <!-- 使用者訊息 -->
                <div
                  v-if="msgData.userId"
                  class="chat-avatar"
                >
                  <b-avatar
                    size="36"
                    class="avatar-border-2 box-shadow-1"
                    :src="msgData.user_info.image"
                    variant="primary"
                  />
                  <div
                    v-b-tooltip.hover.v-secondary
                    :title="ui.onlineStatus[0][msgData.user_info.online_status]"
                    class="remark-user-state state-dot"
                    :class="ui.onlineStatus[1][msgData.user_info.online_status]"
                  />
                </div>

                <div
                  v-if="msgData.userId"
                  class="show-text"
                >
                  <span class="ml-1">{{ msgData.user_info.name }}</span>
                </div>

                <!-- 訊息 -->
                <div class="chat-body mr-0">
                  <div
                    v-contextmenu:msg-menu-action
                    class="chat-content p-0"
                    @contextmenu.prevent="(event) => {
                      rightClick = msgData
                    }"
                  >
                    <div v-if="msgData.message">
                      <div class="px-1 pt-1">
                        <p
                          v-if="msgData.role === 'user'"
                          class="room-text"
                          v-html="formatMessage(msgData.message)"
                        />
                        <vue-markdown
                          v-else
                          v-highlight
                          :source="msgData.message"
                          class="vue-markdown"
                        />
                      </div>
                    </div>

                    <!-- <div
                      v-else-if="msgData.role === 'loading'"
                      class="p-1"
                    >
                      loading...
                    </div> -->

                    <div
                      v-else
                      class="p-1 text-muted"
                    >
                      空訊息
                    </div>

                    <div class="text-right text-muted px-50 pb-25 ml-1">
                      <small>
                        {{ `${moment(msgData.created_at).format('a hh')}:${moment(msgData.created_at).format('mm')}` }}

                        <!-- {{ msgData.rid }} -->
                        <!-- <span v-if="msgData.userId === profileUserData.id">
                          <b-img
                            v-if="!msgData.rid"
                            src="/admin/images/todo/action/sent.svg"
                            width="12"
                          />

                          <feather-icon
                            v-else
                            icon="ClockIcon"
                            size="10"
                            style="margin-top: -3px"
                          />
                        </span> -->
                      </small>
                    </div>

                    <div
                      v-if="msgData.id && msgData.id === searchPoint"
                      class="chat-content-search-area"
                    />

                    <!-- {{ msgData.id }} / {{ searchPoint }} -->
                  </div>
                </div>
              </div>

              <!-- 加載中 -->
              <div
                v-show="roomInfo.data.status === 'waitResponse'"
                class="chat"
              >
                <div class="chat-body mr-0">
                  <div class="chat-content p-1">
                    <div class="loading-loader">
                      <div class="loader-inner">
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                        <div class="loader-block" />
                      </div>
                    </div>

                    <!-- <div class="p-1">
                      空訊息
                    </div> -->
                  </div>
                </div>
              </div>
            </template>

            <!-- 不知道為甚麼，但這一行得保留才正常 -->
            <span v-show="false">{{ formattedChatData.length }}</span>

            <!-- <vue-context
              ref="msg-menu-action"
              :height-offset="150"
            /> -->

            <v-contextmenu
              ref="msg-menu-action"
              :class="$store.state.appConfig.layout.skin === 'dark' ? 'my-contextmenu-dark' : 'my-contextmenu'"
            >
              <div>
                <v-contextmenu-item
                  class="dropdown-item-area"
                  @click="submitCopyMessage"
                >
                  <b-img
                    src="/admin/images/todo/action/copy.svg"
                    class="dropdown-item-btn-image mr-50"
                    rounded
                  />
                  <span>複製文字</span>
                </v-contextmenu-item>
              </div>
            </v-contextmenu>
          </div>

          <!-- 訊息讀取動畫 -->
          <div
            v-show="isMessageLoading"
            class="msg-list animate__animated animate__fadeIn"
          >
            <div
              class="d-flex justify-content-center align-items-center"
              style="height: 300px;"
            >
              <div class="wrapper">
                <div class="circle" />
                <div class="circle" />
                <div class="circle" />
                <div class="shadow" />
                <div class="shadow" />
                <div class="shadow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 訊息留言 -->
    <b-modal
      id="roomSiteModal"
      no-close-on-backdrop
      centered
      @hidden="handleHideSiteModal"
      @close="handleHideSiteModal"
    >
      <template #modal-title>
        <h4 class="m-0">
          <div v-if="previewFile.length === 1">
            傳送{{ siteType === 'image' ? '圖片' : '檔案' }}
          </div>

          <div v-else>
            已選擇{{ previewFile.length }}個{{ siteType === 'image' ? '圖片' : '檔案' }}
          </div>
        </h4>
      </template>

      <div class="site-modal">
        <div v-if="siteType === 'image'">
          <b-row v-if="previewFile.length >=2">
            <b-col
              v-for="(image, index) in previewFile"
              :key="index"
              cols="12"
              lg="6"
              class="mb-1"
            >
              <div class="site-modal-images">
                <div class="site-modal-tool">
                  <feather-icon
                    icon="RefreshCcwIcon"
                    size="16"
                    class="site-modal-tool-icon mr-1"
                    @click="handleChangeSite(index)"
                  />

                  <feather-icon
                    icon="Trash2Icon"
                    size="16"
                    class="site-modal-tool-icon"
                    @click="submitDeleteAppend(index)"
                  />
                </div>

                <b-img
                  :src="image.src"
                  fluid
                  class="site-modal-image"
                />
              </div>
            </b-col>
          </b-row>

          <b-row v-if="previewFile.length ===1">
            <b-col
              v-for="(image, index) in previewFile"
              :key="index"
              cols="12"
              lg="12"
              class="mb-1"
            >
              <div class="site-modal-images">
                <div class="site-modal-tool">
                  <feather-icon
                    icon="RefreshCcwIcon"
                    size="16"
                    class="site-modal-tool-icon mr-1"
                  />

                  <feather-icon
                    icon="Trash2Icon"
                    size="16"
                    class="site-modal-tool-icon"
                    @click="submitDeleteAppend(index)"
                  />
                </div>

                <b-img
                  :src="image.src"
                  fluid
                  class="site-modal-image"
                />
              </div>
            </b-col>
          </b-row>
        </div>

        <div v-if="siteType === 'file'">
          <b-media
            v-for="(append, index) in previewFile"
            :key="index"
            no-body
            class=""
            :class="{'mb-2': previewFile.length !== index + 1 }"
          >
            <b-media-aside class="media-left mr-50">
              <b-avatar
                class="avatar"
                size="40"
                variant="light-primary"
              >
                <feather-icon
                  size="21"
                  icon="FolderIcon"
                />
              </b-avatar>
            </b-media-aside>

            <b-media-body>
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h5 class="mb-25">
                    <div>
                      {{ resolveFileNameLong(append.file.name, 10) }}
                    </div>
                  </h5>
                  <span class="text-muted">{{ convertBytes(append.file.size ? append.file.size : 0) }}</span>
                </div>

                <div class="ml-1 site-modal-files">
                  <div class="site-modal-tool">
                    <feather-icon
                      icon="RefreshCcwIcon"
                      size="16"
                      class="site-modal-tool-icon mr-1"
                      @click="handleChangeSite(index)"
                    />

                    <feather-icon
                      icon="Trash2Icon"
                      size="16"
                      class="site-modal-tool-icon"
                      @click="submitDeleteAppend(index)"
                    />
                  </div>
                </div>
              </div>
            </b-media-body>
          </b-media>
        </div>

        <input
          ref="fileChange"
          type="file"
          hidden
          @input="changeFile($event)"
        >
      </div>

      <div class="input-area">
        <label>說明文字</label>
        <div class="textarea-section">
          <textarea
            ref="resizeTextarea"
            v-model="siteInput"
            class="w-100 site-input-area"
            :style="{ 'color': !isDark ? '#000' : '#d0d2d6'}"
            @paste="handlePasteSiteModal"
            @input="adjustTextareaHeight"
            @keydown="handleKeyDownSiteModal"
          />

          <div class="emoji-area">
            <my-emoji-component @select-emoji="selectEmojiIconSiteModal">
              <label
                slot="button-content"
                class="cursor-pointer"
              >
                <feather-icon
                  icon="SmileIcon"
                  size="20"
                  class="emoji-icon"
                />
              </label>
            </my-emoji-component>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <div>
            <b-button
              variant="flat-primary"
              :disabled="isMessageBusy"
              @click="$refs.fileInput.click()"
            >
              新增
            </b-button>

            <input
              ref="fileInput"
              type="file"
              multiple
              hidden
              @input="handleAddSiteModal($event)"
            >
          </div>

          <div>
            <b-button
              variant="flat-primary"
              :disabled="isMessageBusy"
              @click="handleHideSiteModal"
            >
              取消
            </b-button>

            <b-button
              variant="flat-primary"
              :disabled="isMessageBusy"
              @click="handleConfirmSiteModal"
            >
              確認
            </b-button>
          </div>
        </div>
      </template>

    </b-modal>

    <!-- 編輯訊息 -->
    <b-modal
      id="roomMessageModal"
      no-close-on-backdrop
      centered
      body-class="p-0"
      @hidden="handleHideMessageModal"
      @close="handleHideMessageModal"
    >
      <template #modal-title>
        <h4 class="m-0">
          編輯訊息
        </h4>
      </template>

      <div class="mt-1">
        <div class="input-area">
          <div class="textarea-section">
            <textarea
              ref="resizeTextareaMessage"
              v-model="inputMessage"
              class="w-100 message-input-area pl-2"
              :style="{ 'color': !isDark ? '#000' : '#d0d2d6'}"
              @input="adjustTextareaMessageHeight"
              @keydown="handleKeyDownMessageModal"
            />
            <div class="emoji-area">
              <my-emoji-component @select-emoji="selectEmojiIconMessageModal">
                <label
                  slot="button-content"
                  class="cursor-pointer"
                >
                  <feather-icon
                    icon="SmileIcon"
                    size="20"
                    class="emoji-icon"
                  />
                </label>
              </my-emoji-component>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="text-right">
          <b-button
            variant="flat-primary"
            :disabled="isMessageBusy"
            @click="handleHideMessageModal"
          >
            取消
          </b-button>

          <b-button
            variant="flat-primary"
            :disabled="isMessageBusy"
            @click="handleConfirmMessageModal"
          >
            確認
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import {
  BImg, BModal, BButton, BRow, BCol, VBTooltip, BAvatar, BMedia, BMediaAside, BMediaBody,
} from 'bootstrap-vue'
import moment from 'moment'
import vueMarkdown from 'vue-markdown'
import MyEmojiComponent from '@/layouts/components/EmojiPicker/EmojiPicker.vue'
import {
  useOpenAISetting, useRoomMessage,
} from '../useOpenAI'
import { useAlert, useSwalToast } from '@/libs/mixins/index'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BModal,
    BButton,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    vueMarkdown,
    MyEmojiComponent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    roomInfo: {
      type: Object,
      required: true,
    },
    roomMessage: {
      type: Array,
      required: true,
    },
    roomInput: {
      type: String,
      required: true,
    },
    profileUserData: {
      type: Object,
      required: true,
    },
    isMessageLoading: {
      type: Boolean,
      required: true,
    },
    searchPoint: {
      type: Number || null,
      default: null,
    },
  },
  data() {
    return {
      editCol: '',
      editColData: '',
      showDescription: false,
      showMore: false,
      siteFile: [],
      siteInput: null,
      siteType: 'image',
      siteIndex: null,
      siteLock: null,
      siteSize: 15728640,
      inputMessage: '',
      previewFile: [],
      previewImage: null,
      isImageBusy: false,
      rightClick: {},
    }
  },
  computed: {
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    formatMessage(message) {
      // let replacedText = message
      // // eslint-disable-next-line
      // const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
      // replacedText = message.replace(replacePattern1, '<a href="$1" target="_blank"">$1</a>')
      // // onclick="return false

      // // eslint-disable-next-line
      // const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
      // replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank"">$2</a>')

      // // eslint-disable-next-line
      // const replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim
      // replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1"">$1</a>')
      // return replacedText.replace(/\n/g, '<br>')

      let replacedText = message

      // 將 HTML 標籤轉換為純文字
      replacedText = replacedText.replace(/</g, '&lt;')
      replacedText = replacedText.replace(/>/g, '&gt;')

      // 將超連結轉換成可點擊的連結
      // eslint-disable-next-line
      const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
      replacedText = replacedText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>')

      // eslint-disable-next-line
      const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
      replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>')

      // eslint-disable-next-line
      const replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim
      replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>')

      // 將換行符號轉換成<br>標籤
      replacedText = replacedText.replace(/\n/g, '<br>')

      return replacedText
    },

    // (轉化)檔案大小
    convertBytes(bytes) {
      const kilobytes = (bytes / 1024).toFixed(1)
      const gigabytes = (kilobytes / (1024 * 1024)).toFixed(1)
      const megabytes = (kilobytes / 1024).toFixed(1)

      if (kilobytes >= 1024 * 1024) return `${gigabytes.toLocaleString()} GB`
      if (kilobytes >= 1024) return `${megabytes.toLocaleString()} MB`
      return `${kilobytes.toLocaleString()} KB`
    },

    // (顯示)圖片數量
    filteredImages(array) {
      const newArray = array
      return newArray.slice(0, 3)
    },

    // (顯示)檔案字數
    resolveFileNameLong(text, length) {
      if (text && text.length > length * 2) {
        const start = text.slice(0, length)
        const end = text.slice(-length)
        return `${start}...${end}`
      }
      return text
    },

    // (關閉)右鍵選擇動作
    closeAction() {
      if (!this.$refs['msg-menu-action']) return
      this.$refs['msg-menu-action'].hide()
    },

    // (轉成)Base64
    readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => resolve(e.target.result)
        reader.onerror = error => reject(error)
        reader.readAsDataURL(file)
      })
    },

    // (觸發)圖片顯示
    showImgs(array, index) {
      const imageList = array
      const newArray = imageList.slice(index).concat(imageList.slice(0, index)).map(item => item.preview_image)
      this.$viewerApi({
        images: newArray,
        options: {
          movable: false,
        },
      })
    },
    // ------------------------------------------------------------------------------------
    preventDefault(e) {
      e.stopPropagation()
      e.preventDefault()
    },

    dragEnter(e) {
      this.preventDefault(e)
      this.elementNode = e.target
      const { types } = e.dataTransfer
      if (types.includes('Files')) {
        this.$refs.dropArea.classList.remove('hidden')
      }
    },

    dragLeave(e) {
      this.preventDefault(e)
      if (this.elementNode === e.target) this.$refs.dropArea.classList.add('hidden')
    },

    dropFile(e) {
      this.preventDefault(e)
      const { files, types } = e.dataTransfer
      this.siteFile = Object.values(files)

      if (!types.includes('Files')) return
      if (this.siteFile === 0) return

      files.forEach(file => {
        const previewList = {
          file,
          src: null,
        }
        if (!this.judgeTypeOption.includes(file.type)) this.siteType = 'file'
        else if (file.size > this.siteSize) this.siteType = 'file'
        else {
          const objectURL = window.URL.createObjectURL(file)
          previewList.src = objectURL
        }
        this.previewFile.push(previewList)
      })
      this.siteInput = JSON.parse(JSON.stringify(this.roomInput))
      this.$bvModal.show('roomSiteModal')
      this.$refs.dropArea.classList.add('hidden')
      setTimeout(() => {
        this.$refs.resizeTextarea.focus()
      }, 100)
    },

    // (匯入)檔案(V)
    importFile(e) {
      const { files } = e
      this.siteFile = Object.values(files)
      files.forEach(file => {
        const previewList = {
          file,
          src: null,
        }
        if (!this.judgeTypeOption.includes(file.type)) this.siteType = 'file'
        else if (file.size > this.siteSize) this.siteType = 'file'
        else {
          const objectURL = window.URL.createObjectURL(file)
          previewList.src = objectURL
        }
        this.previewFile.push(previewList)
      })
      this.siteInput = JSON.parse(JSON.stringify(this.roomInput))
      this.$bvModal.show('roomSiteModal')
      setTimeout(() => {
        this.$refs.resizeTextarea.focus()
      }, 100)
    },

    // (變更)檔案(V)
    changeFile(e) {
      const { files } = e.target
      const [file] = files
      const previewList = {
        file,
        src: null,
      }
      if (!this.judgeTypeOption.includes(file.type)) this.siteType = 'file'
      else if (file.size > this.siteSize) this.siteType = 'file'
      else {
        const objectURL = window.URL.createObjectURL(file)
        previewList.src = objectURL
      }
      this.previewFile.splice(this.siteIndex, 1, previewList)
    },

    // (觸發)變更檔案(V)
    handleChangeSite(index) {
      if (index === null) return
      this.$refs.fileChange.click()
      this.siteIndex = index
    },

    // (新增)檔案(V)
    handleAddSiteModal(e) {
      const { files } = e.target
      this.siteFile.push(Object.values(files))
      files.forEach(file => {
        const previewList = {
          file,
          src: null,
        }
        if (!this.judgeTypeOption.includes(file.type)) this.siteType = 'file'
        else if (file.size > this.siteSize) this.siteType = 'file'
        else {
          const objectURL = window.URL.createObjectURL(file)
          previewList.src = objectURL
        }
        this.previewFile.push(previewList)
      })
    },

    // (匯入)檔案(V)
    importPasteFile(e) {
      const { files } = e
      this.siteFile = Object.values(files)
      files.forEach(file => {
        const previewList = {
          file,
          src: null,
        }
        if (!this.judgeTypeOption.includes(file.type)) this.siteType = 'file'
        else if (file.size > this.siteSize) this.siteType = 'file'
        else {
          const objectURL = window.URL.createObjectURL(file)
          previewList.src = objectURL
        }
        this.previewFile.push(previewList)
      })
    },

    // (調整)訊息檔案輸入欄位
    adjustTextareaHeight() {
      this.$nextTick(() => {
        const textarea = this.$refs.resizeTextarea
        textarea.style.height = '30px'
        if (textarea.scrollHeight < 100) {
          textarea.style.height = `${textarea.scrollHeight}px`
        } else {
          textarea.style.height = '100px'
        }
      })
    },

    // (觸發)輸入欄位按鈕
    handleKeyDownSiteModal(event) {
      const textareaRef = this.$refs.resizeTextarea
      const cursorPosition = textareaRef.selectionStart
      if (event.shiftKey && event.key === 'Enter') {
        this.siteInput = `${this.siteInput.slice(0, cursorPosition)}\n${this.siteInput.slice(cursorPosition)}`
        event.preventDefault()
        const newCursorPosition = cursorPosition + 1
        setTimeout(() => {
          textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
          this.adjustTextareaHeight()
        }, 100)
      }
      if (event.ctrlKey && event.key === 'Enter') {
        this.siteInput = `${this.siteInput.slice(0, cursorPosition)}\n${this.siteInput.slice(cursorPosition)}`
        event.preventDefault()
        const newCursorPosition = cursorPosition + 1
        setTimeout(() => {
          textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
          this.adjustTextareaHeight()
        }, 100)
      }
      if (!event.shiftKey && event.key === 'Enter') {
        event.preventDefault()
        this.handleConfirmSiteModal()
      }
    },

    // (觸發)貼上
    handlePasteSiteModal() {
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      navigator.clipboard.read()
        .then(clipboardItems => {
          clipboardItems.forEach(clipboardItem => {
            const { types } = clipboardItem
            if (types.length === 1) {
              const type = types[0]
              if (type === 'image/png' || type === 'image/jpeg') {
                clipboardItem.getType(type).then(blob => {
                  const file = new File([blob], 'pasted_image.png', { type: blob.type })
                  this.importPasteFile({ files: [file] })
                })
              }
            }
            if (types.length === 2) {
              if (types.includes('text/html') && types.includes('image/png')) {
                const type = types[1]
                clipboardItem.getType(type).then(blob => {
                  const file = new File([blob], 'pasted_html_image.png', { type: blob.type })
                  this.importPasteFile({ files: [file] })
                })
              }
            }
          })
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },

    // (新增)EMOJI
    selectEmojiIconSiteModal(emoji) {
      const textareaRef = this.$refs.resizeTextarea
      const cursorPosition = textareaRef.selectionStart
      this.siteInput = `${this.siteInput.slice(0, cursorPosition)}${emoji}${this.siteInput.slice(cursorPosition)}`
      const newCursorPosition = cursorPosition + emoji.length
      setTimeout(() => {
        textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
      }, 100)
    },

    // (關閉)彈窗
    handleHideSiteModal() {
      if (this.isMessageBusy) return
      this.$bvModal.hide('roomSiteModal')
      this.siteType = 'image'
      this.siteFile = []
      this.previewFile = []
    },

    // (確認)彈窗
    async handleConfirmSiteModal() {
      if (this.siteType === 'image') {
        this.isMessageBusy = true
        // 圖片轉檔
        const sendImageInput = {
          message: this.siteInput,
          images: [],
          reply_id: this.replyId,
        }
        try {
          const base64Array = await Promise.all(
            this.previewFile.map(file => this.readFileAsBase64(file.file)),
          )

          sendImageInput.images = base64Array
        } catch (error) {
          // console.error(error)
        }

        // 預覽圖片
        const previewImageMessage = {
          message: this.siteInput,
          body: {
            images: [],
          },
          user_id: this.$store.state.app.userData.id,
        }
        const images = sendImageInput.images.map(item => {
          const resolveImage = this.syncObject(this.blankImageData, { preview_image: item })
          return resolveImage
        })
        previewImageMessage.body.images = images
        this.$emit('create-remark-data', previewImageMessage)

        this.$bvModal.hide('roomSiteModal')
        this.siteType = 'image'
        this.siteFile = []
        this.previewFile = []

        this.setOpenAIMessageCreate({
          room_id: this.roomInfo.data.id,
          data: {
            ...sendImageInput,
          },
        })
          .then(response => {
            const { data } = response.data
            const resolveResponse = this.resolveMessageInfoData({ ...data })
            const newImages = resolveResponse.body.images.map((item, index) => {
              const imageData = this.syncObject(this.blankImageData, item)
              imageData.preview_image = sendImageInput.images[index]
              return imageData
            })
            resolveResponse.body.images = newImages
            resolveResponse.user.name = this.profileUserData.name
            this.$emit('update-remark-data', this.randomKey, resolveResponse)

            this.$store.dispatch('app/isSocketFunctionExist', 'roomCreateMessage')
              .then(state => {
                if (state) {
                  window.socket.roomCreateMessage({
                    room_id: this.roomInfo.data.id,
                    remark_data: {
                      ...resolveResponse,
                    },
                  })
                }
              })
            this.isMessageBusy = false
          })
          .catch(() => {
            this.$emit('delete-remark-data', this.randomKey)
            this.isMessageBusy = false
          })
      }
      if (this.siteType === 'file') {
        this.isMessageBusy = true
        // 預覽圖片
        const previewAppendMessage = {
          message: this.siteInput,
          appends: [],
          user_id: this.$store.state.app.userData.id,
        }

        this.previewFile.forEach(fileItem => {
          const { file } = fileItem
          const rId = this.generateRandomString()
          const resolveData = {
            rid: rId,
            name: file.name,
            size: file.size,
            file,
          }
          const resolveAppend = this.syncObject(this.blankAppendData, resolveData)
          previewAppendMessage.appends.push(resolveAppend)
          this.$bvModal.hide('roomSiteModal')
        })
        this.$emit('create-remark-data', previewAppendMessage)

        this.setOpenAIMessageCreate({
          room_id: this.roomInfo.data.id,
          data: {
            message: this.siteInput,
            reply_id: this.replyId,
          },
        })
          .then(response => {
            const { data } = response.data
            const { id } = data

            const currentRandomKey = this.randomKey

            const resolveResponse = this.resolveMessageInfoData({ ...data, rid: currentRandomKey })
            resolveResponse.appends = previewAppendMessage.appends
            this.$emit('update-remark-data', currentRandomKey, resolveResponse)
            this.isMessageBusy = false

            resolveResponse.appends.forEach(fileItem => {
              const { file } = fileItem

              // console.log(file)
              const formData = new FormData()
              formData.append('data', file)

              this.setMessageAppendUpload({
                remark_id: id,
                data: formData,
              })
                .then(upload => {
                  // console.log(upload)
                  this.$emit('update-remark-append', currentRandomKey, fileItem.rid, upload.data.data, resolveResponse)
                })
            })
            this.isMessageBusy = false
            this.handleHideSiteModal()
          })
          .catch(() => { this.isMessageBusy = false })
      }
    },

    // ------------------------------------編輯訊息文字-----------------------------------------
    // (觸發)編輯訊息文字
    submitEditMessage(remark) {
      if (remark.id) this.rightClick = JSON.parse(JSON.stringify(remark))
      const { message } = this.rightClick
      this.inputMessage = message
      this.$bvModal.show('roomMessageModal')
      setTimeout(() => {
        this.adjustTextareaMessageHeight()
        this.$refs.resizeTextareaMessage.focus()
      }, 500)
    },

    // (關閉)編輯彈窗
    handleHideMessageModal() {
      if (this.isMessageBusy) return
      this.$bvModal.hide('roomMessageModal')
    },

    // (調整)訊息文字輸入欄位
    adjustTextareaMessageHeight() {
      this.$nextTick(() => {
        const textarea = this.$refs.resizeTextareaMessage
        textarea.style.height = '30px'
        if (textarea.scrollHeight < 400) {
          textarea.style.height = `${textarea.scrollHeight}px`
        } else {
          textarea.style.height = '400px'
        }
      })
    },

    // (觸發)輸入欄位按鈕
    handleKeyDownMessageModal(event) {
      const textareaRef = this.$refs.resizeTextareaMessage
      const cursorPosition = textareaRef.selectionStart
      if (event.shiftKey && event.key === 'Enter') {
        this.inputMessage = `${this.inputMessage.slice(0, cursorPosition)}\n${this.inputMessage.slice(cursorPosition)}`
        event.preventDefault()
        const newCursorPosition = cursorPosition + 1
        setTimeout(() => {
          textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
          this.adjustTextareaMessageHeight()
        }, 100)
      }
      if (event.ctrlKey && event.key === 'Enter') {
        this.inputMessage = `${this.inputMessage.slice(0, cursorPosition)}\n${this.inputMessage.slice(cursorPosition)}`
        event.preventDefault()
        const newCursorPosition = cursorPosition + 1
        setTimeout(() => {
          textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
          this.adjustTextareaMessageHeight()
        }, 100)
      }
      if (!event.shiftKey && event.key === 'Enter') {
        event.preventDefault()
        this.handleConfirmMessageModal()
      }
    },

    // (新增)EMOJI
    selectEmojiIconMessageModal(emoji) {
      const textareaRef = this.$refs.resizeTextareaMessage
      const cursorPosition = textareaRef.selectionStart
      this.inputMessage = `${this.inputMessage.slice(0, cursorPosition)}${emoji}${this.inputMessage.slice(cursorPosition)}`
      const newCursorPosition = cursorPosition + emoji.length
      setTimeout(() => {
        textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
      }, 100)
    },

    // (確認)編輯彈窗
    handleConfirmMessageModal() {
      this.$emit('update-remark-message', 'edit', this.rightClick.id, this.inputMessage)
      this.handleHideMessageModal()
      if (this.rightClick.user_id !== this.profileUserData.id) return
      this.setOpenAIMessageUpdate({
        room_id: this.roomInfo.data.id,
        remark_id: this.rightClick.id,
        data: {
          message: this.inputMessage,
        },
      })
        .then(response => {
          const { data } = response.data
          const { id } = data

          this.$emit('update-remark-message', 'real', id, data)
          this.isMessageBusy = false
        })
        .catch(error => {
          this.isMessageBusy = false
          this.$emit('update-remark-message', 'error', this.rightClick.id, {
            error: error.response,
            message: this.rightClick.message,
            input: this.inputMessage,
          })
        })
    },
    // ------------------------------------------------------------------------------------
    // (觸發)下載附件
    submitDownloadAppendKey(remark, id) {
      if (!remark.id) return
      if (!id) return
      if (this.siteLock) return
      this.siteLock = id
      this.getMessageAppendDownloadKey({
        remark_id: remark.id,
        append_id: id,
      })
        .then(response => {
          const { key } = response.data.data
          // 創建一個<a>元素
          const link = document.createElement('a')
          // http://192.168.1.139
          link.href = `/file/download/room/remark/append?key=${key}`
          // 模擬點擊下載連結
          link.click()
          // window.open(`/file/download/remark/append?key=${key}`, '_blank')
          this.siteLock = null
        })
        .catch(error => {
          this.siteLock = null
          this.useHttpCodeAlert(error.response)
        })
    },

    // (觸發)刪除附件
    submitDeleteAppend(index) {
      this.previewFile.splice(index, 1)
      if (this.previewFile.length === 0) this.handleHideSiteModal()
    },

    // ------------------------------------------------------------------------------------
    // (觸發)複製訊息文字(O)
    submitCopyMessage() {
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      const { message } = this.rightClick
      if (!message) return
      navigator.clipboard.writeText(message)
        .then(() => {
          this.useAlertToast(true, '訊息文字已複製')
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },

  },
  setup(props) {
    // , { emit }
    moment.locale('zh-tw')
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    const {
      ui,
      syncObject,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useOpenAISetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const {
      isSameDay,
      isMessageBusy,
      messageText,
      openAIRoomMessageData,

      getOpenAIMessage,
      setOpenAIMessageCreate,
      resolveRoomInfoData,
      resolveMessageInfoData,
    } = useRoomMessage()

    const formattedChatData = computed(() => {
      // console.log('formattedChatData', props.roomMessage)
      let chatLog = []
      chatLog = JSON.parse(JSON.stringify(props.roomMessage))

      const formattedChatLog = []
      if (chatLog.length <= 0) return []
      const [firstLog] = chatLog
      let chatMessageSenderId = firstLog ? firstLog.user_id : undefined
      let chatMessageSenderTime = firstLog ? firstLog.created_at : undefined

      let msgGroup = {
        id: firstLog.id,
        role: 'user',
        userId: chatMessageSenderId,
        user_info: {
          name: firstLog ? firstLog.user.name : null,
          image: firstLog ? firstLog.user.image : null,
          // online_status: firstLog ? firstLog.user.online_status : 'offline',
          online_status: (props.profileUserData && props.profileUserData.id === chatMessageSenderId) ? 'online' : firstLog.user.online_status,
        },
        time: chatMessageSenderTime,
        showTime: true,
        message: firstLog ? firstLog.message : null,
      }

      chatLog.forEach((msg, index) => {
        if (index === 0) {
          formattedChatLog.push(msgGroup)
          return
        }
        if (msg.role === 'user') {
          chatMessageSenderId = msg.user_id
          msgGroup = {
            id: msg.id,
            role: msg.role, // 'user'
            userId: msg.user_id,
            user_info: {
              name: msg.user.name ? msg.user.name : null,
              image: msg.user.image ? msg.user.image : null,
              online_status: (props.profileUserData && props.profileUserData.id === msg.user_id) ? 'online' : msg.user.online_status,
            },
            time: msg.created_at,
            showTime: false,
            message: msg.message,
          }
        } else {
          chatMessageSenderId = null
          msgGroup = {
            id: msg.id,
            role: msg.role, // 'assistant'
            userId: null,
            user_info: {
              name: null,
              image: null,
              online_status: 'offline',
            },
            time: msg.created_at ? msg.created_at : null,
            showTime: false,
            message: msg.message ? msg.message : null,
          }
        }
        if (msg.created_at && !isSameDay(msg.created_at, chatMessageSenderTime)) msgGroup.showTime = true
        chatMessageSenderTime = msg.created_at ? msg.created_at : chatMessageSenderTime
        formattedChatLog.push(msgGroup)
      })

      // setTimeout(() => emit('scroll-to-bottom-log'), 200)
      return formattedChatLog || []
    })

    return {
      ui,
      isDark,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,

      formattedChatData,

      isSameDay,
      isMessageBusy,
      messageText,
      openAIRoomMessageData,

      getOpenAIMessage,
      setOpenAIMessageCreate,
      resolveRoomInfoData,
      resolveMessageInfoData,

      useAlertToast,
      useHttpCodeAlert,
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-context.scss';
.score-area {
  display: flex;
  .score-text {
    width: 22px;
  }
}
.vue-markdown{
  word-break: break-all;
   pre {
    background-color: #0d0d0d !important;
   }
}
</style>

<style lang="scss" scoped>
.room-text {
  word-break: break-all;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.2; /* 你想要的行高，可以自行調整 */
  overflow: hidden;
}

.profile-image {
  position: relative;
  .profile-image-mask {
    display: none;
    position: absolute;
    z-index: 1;
    width: 120%;
    height: 120%;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #00000033;
    user-select: none;
    .profile-icon {
      cursor: pointer;
    }
    .profile-image-reset {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px #ffffffbd solid;
      background-color: #ffffffbd;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &:hover {
    .profile-image-mask {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.chat-avatar {
  position: relative;
  .remark-user-state {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.contact-method {
  position: relative;
  margin-right: 1rem;
  .contact-icon {
    filter: grayscale(1);
  }
  .contact-input {
    position: absolute;
    opacity: 0;
  }
  &:active {
    .contact-icon {
      filter: none;
    }
    .contact-input {
      position: relative;
      opacity: 1;
    }
  }
}

.reply-area {
  margin: 8px 8px 0 8px;
  padding: 5px 10px;
  background-color: #afb1b439;
  border-radius: 3px 6px;
  border-left: 2px solid #419fd984;
  cursor: pointer;
  min-width: 100px;
}
.hover-content {
  cursor: pointer;
  border-radius: 10px;
  .hover-content-editor {
    min-height: 50px;
  }
}
.link-card {
  border-radius: 5px;
  .link-card-image {
    width: 16px;
    height: 16px;
  }

  &:hover {
    cursor: pointer;
    .link-card-image {
      animation: breathe .8s linear infinite;
    }
  }
}

@keyframes breathe {
  0%{ transform: scale(.97); }
  50%{ transform: scale(1.1); }
  100%{ transform: scale(.97); }
}

.show-description-arrow {
  transition: transform 0.3s ease-in-out;
  .active {
    transition: transform 0.3s ease-in-out;
    transform: rotate(-90deg);
  }
}

.upload-card {
  position: relative;
  min-height: 70vh;
  .dropArea {
    min-height: 50%;
    position: sticky;
    z-index: 1000;
    padding: 30px;
    top: -30px;
    bottom: -30px;
    right: -30px;
    left: -30px;
    background-color: #282828;
    min-height: 90vh;
    opacity: .9;
    text-align: center;
    align-self: center;
    color: #fff;
    &.hidden {
      display: none;
    }
  }
  .upload-label {
    position: sticky;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    border-radius: 10px;
    height: 40vh;
    border: #898989 dashed 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .label-title {
      display: block;
      font-size: 2rem;
      font-weight: 900;
      padding: 1rem 0 0.5rem;
    }
    .label-subtitle {
      font-size: 1rem;
    }
  }

  .upload-label-text {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 900;
    padding: 1rem 0 0.5rem;
  }
}
.site-input-area {
  background: transparent;
  border: none;
  border-radius: 0;
  resize: none;
  height: 30px;
  color: #d0d2d6;
  &:focus-visible {
    outline: none;
  }
}

.message-input-area {
  height: 30px;
  border: none;
  background: transparent;
  border-radius: 0;
  resize: none;
  color: #d0d2d6;
  &:focus-visible {
    outline: none;
  }
}

.more-images-area {
  position: relative;
  .more-images {
    cursor: pointer;
  }
  .more-images-filter {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 12px;
    left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    background-color: rgba(53, 53, 53, 0.2);
    backdrop-filter: blur(1px);
    color: rgba(255, 255, 255, 0.857);
    font-weight: 600;
  }
}

.chat-content {
  position: relative;
  .image-area {
    max-height: 200px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
  pointer-events: none;
  }

  .chat-content-selected-area {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    background-color: rgba(108, 196, 255, 0.2);
    border-radius: 5px;
  }

  .chat-content-search-area {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 208, 1, 0.2);
    border-radius: 5px;
  }
}
.chat-content-rtl {
  transform: scaleX(-1);
}

.site-modal {
  .site-modal-images {
    .site-modal-tool {
      position: absolute;
      padding: 5px 10px;
      background-color: rgba(51, 51, 51, 0.59);
      border-radius: 5px;
      color: white;
      top: 5%;
      right: 8%;
      .site-modal-tool-icon {
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
  .site-modal-files {
    .site-modal-tool {
      .site-modal-tool-icon {
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}

.input-area {
  border-bottom: 2px solid #419fd9;
}

.textarea-section {
  position: relative;
  .emoji-area {
    position: absolute;
    bottom: 0;
    right: 8px;
    margin-top: 20px;
  }
}

.dropdown-item-area {
  transition: transform ease-out 200ms;
  .dropdown-item-btn-image {
    width: 12px;
    height: 12px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-room-dropdown .8s linear infinite;
    }
  }
}

.loading-loader {
  padding: 3px 0;
  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border-radius: 50%;
  }

  .loader-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .loader-block {
    display: inline-block;
    width: 4px;
    height: 10px;
    margin: 2px;
    background-color: #419fd9;
    box-shadow: 0 0 1px rgb(53, 143, 246);
    animation: loader 2.5s infinite;
  }

  .loader-block:nth-child(1) {
    animation-delay: 0.1s;
  }

  .loader-block:nth-child(2) {
    animation-delay: 0.2s;
  }

  .loader-block:nth-child(3) {
    animation-delay: 0.3s;
  }

  .loader-block:nth-child(4) {
    animation-delay: 0.4s;
  }

  .loader-block:nth-child(5) {
    animation-delay: 0.5s;
  }

  .loader-block:nth-child(6) {
    animation-delay: 0.6s;
  }

  .loader-block:nth-child(7) {
    animation-delay: 0.7s;
  }

  .loader-block:nth-child(8) {
    animation-delay: 0.8s;
  }

  .loader-block:nth-child(9) {
    animation-delay: 0.9s;
  }

  .loader-block:nth-child(10) {
    animation-delay: 1s;
  }

  .loader-block:nth-child(11) {
    animation-delay: 1.1s;
  }

  .loader-block:nth-child(12) {
    animation-delay: 1.2s;
  }

  .loader-block:nth-child(13) {
    animation-delay: 1.3s;
  }

  .loader-block:nth-child(14) {
    animation-delay: 1.4s;
  }

  .loader-block:nth-child(15) {
    animation-delay: 1.5s;
  }

  .loader-block:nth-child(16) {
    animation-delay: 1.6s;
  }

  .loader-block:nth-child(17) {
    animation-delay: 1.7s;
  }

  .loader-block:nth-child(18) {
    animation-delay: 1.8s;
  }

  .loader-block:nth-child(19) {
    animation-delay: 1.9s;
  }

  .loader-block:nth-child(20) {
    animation-delay: 2s;
  }

  .loader-block:nth-child(21) {
    animation-delay: 2.1s;
  }

  .loader-block:nth-child(22) {
    animation-delay: 2.2s;
  }

  .loader-block:nth-child(23) {
    animation-delay: 2.3s;
  }

  .loader-block:nth-child(24) {
    animation-delay: 2.4s;
  }

  @keyframes loader {
    0% {
      transform: scale(1);
    }

    13% {
      transform: scale(1, 2);
    }

    26% {
      transform: scale(1);
    }
  }
}

@keyframes btn-breathe-room-dropdown {
  0%{ transform: scale(0.8); }
  50%{ transform: scale(1.2); }
  100%{ transform: scale(0.8); }
}
</style>

<style lang="scss" scoped>
.wrapper {
  width: 200px;
  height: 60px;
  position: relative;
  z-index: 1;
}

.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #abd5ee;
  left: 15%;
  transform-origin: 50%;
  animation: circle7124 .5s alternate infinite ease;
}

@keyframes circle7124 {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.circle:nth-child(2) {
  left: 45%;
  animation-delay: .2s;
}

.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: .3s;
}

.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(147, 147, 147, 0.9);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow046 .5s alternate infinite ease;
}

@keyframes shadow046 {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: .7;
  }

  100% {
    transform: scaleX(.2);
    opacity: .4;
  }
}

.shadow:nth-child(4) {
  left: 45%;
  animation-delay: .2s
}

.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: .3s;
}
</style>
