import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // ----------------------------------------------------------------
    // ----------------------OpenAI機器人-------------------------------
    // ----------------------------------------------------------------
    // (查詢)機器人列表
    getOpenAIList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/openAI/role', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)機器人
    setOpenAICreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/openAI/role', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)機器人
    setOpenAIUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/openAI/role/${resolveData.openAI_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (上傳)機器人頭像
    setOpenAIImageUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/openAI/role/${resolveData.openAI_id}/image`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: resolveData.data,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)機器人頭像
    setOpenAIImageDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/openAI/role/${resolveData.openAI_id}/image`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: resolveData.data,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)機器人
    setOpenAIDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/openAI/role/${resolveData.openAI_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // ----------------------------------------------------------------
    // ----------------------OpenAI聊天室-------------------------------
    // ----------------------------------------------------------------
    // (查詢)聊天室列表
    getOpenAIRoomList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/openAI/room', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)聊天室
    setOpenAIRoomCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/openAI/room', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)聊天室
    setOpenAIRoomUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/openAI/room/${resolveData.room_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)聊天室
    setOpenAIRoomDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/openAI/room/${resolveData.room_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: resolveData.data,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // ----------------------------------------------------------------
    // ---------------------OpenAI聊天室紀錄----------------------------
    // ----------------------------------------------------------------
    // (查詢)聊天室紀錄
    getOpenAIMessage(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/openAI/room/${resolveData.room_id}/message`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // (新增)聊天室
    setOpenAIMessageCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/openAI/room/${resolveData.room_id}/message`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
