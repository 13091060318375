<template>
  <div>
    <b-modal
      id="openAI-rename-modal"
      no-close-on-backdrop
      centered
      header-bg-variant="primary"
      @hidden="handleHideModal"
      @close="handleHideModal"
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          聊天室名稱
        </h4>
      </template>

      <validation-observer ref="refFormObserver">
        <!-- ="handleSubmit(onSubmit)"  #default="{ handleSubmit }"-->
        <b-form
          @submit.prevent
          @reset.prevent="resetForm"
        >
          <!-- 名稱 -->
          <validation-provider
            #default="{ errors }"
            name="名稱"
            rules="required"
          >
            <b-form-group label-for="name">
              <template #label>
                <label class="mb-0">
                  名稱
                  <span class="text-danger">*</span>
                </label>
              </template>

              <b-form-input
                id="name"
                v-model="OpenAiData.name"
                :state="errors.length > 0 ? false : null"
                trim
                :placeholder="oriNameData ? oriNameData : '請輸入名稱'"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>

      <template #modal-footer>
        <div class="w-100 text-right">
          <b-button
            variant="primary"
            :disabled="isBusy"
            @click="handleConfirmModal"
          >
            <span v-if="!isBusy">確認</span>

            <b-spinner
              v-else
              small
            />
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BForm, BButton, BSpinner, BFormInvalidFeedback, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useOpenAIRoomList } from '../useOpenAI' // useOpenAISetting,
import { useAlert, useSwalToast } from '@/libs/mixins/index'

export default {
  components: {
    BForm,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [useSwalToast],
  data() {
    return {
      required,
      missingFields: [],
    }
  },
  methods: {
    // (關閉)彈窗
    handleHideModal() {
      // if (this.isBusy) return
      this.$bvModal.hide('openAI-rename-modal')
    },

    // (確認)彈窗
    handleConfirmModal(bvModalEvt) {
      bvModalEvt.preventDefault()

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '名稱',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.OpenAiData[Object.keys(requiredFields)[i]] === null || this.OpenAiData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            lable: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].lable}不得為空`)
        return
      }

      this.isBusy = true

      this.setOpenAIRoomUpdate({
        room_id: this.OpenAiData.id,
        data: {
          room_name: this.OpenAiData.name,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.$nextTick(() => {
            this.$bvModal.hide('openAI-rename-modal')
            this.$emit('refetch-data', this.OpenAiData)
            this.resetOpenAiData()
          })
          this.isBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },

    // (獲取)資料
    getData(item) {
      this.OpenAiData = {
        ...item,
      }
      this.oriNameData = item.name
      this.$bvModal.show('openAI-rename-modal')
    },
  },
  setup() {
    localize('tw')

    const isBusy = ref(false)

    const blankOpenAiData = {
      id: null,
      name: null,
    }

    const OpenAiData = ref(JSON.parse(JSON.stringify(blankOpenAiData)))
    const oriNameData = ref(null)

    const resetOpenAiData = () => {
      OpenAiData.value = JSON.parse(JSON.stringify(blankOpenAiData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetOpenAiData)

    const {
      setOpenAIRoomUpdate,
    } = useOpenAIRoomList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    return {
      isBusy,
      OpenAiData,
      oriNameData,

      useAlertToast,
      useHttpCodeAlert,
      setOpenAIRoomUpdate,
      resetOpenAiData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style scoped>

</style>
