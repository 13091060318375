<template>
  <div>
    <div
      v-if="state"
      class="animate__animated animate__fadeIn"
    >
      <slot name="date-label" />
      <slot name="quick-time-btn" />

      <v-date-picker
        v-model="range"
        mode="date"
        :masks="masksTime"
        is-range
        :min-date="minDate"
        :max-date="maxDate"
        :is-dark="$store.state.appConfig.layout.skin === 'dark'"
        is-expanded
      />

      <div
        v-if="useTime"
        class="mt-1"
      >
        <slot name="time-label" />

        <div class="d-none d-lg-block">
          <div class="time-picker-lg-area">
            <div class="time-picker-only">
              <flat-pickr
                v-model="range.start"
                class="form-control"
                :config="dateConfigLg"
              />
            </div>

            ~

            <div class="time-picker-only">
              <flat-pickr
                v-model="range.end"
                class="form-control"
                :config="dateConfigLg"
              />
            </div>
          </div>
        </div>

        <div class="d-lg-none d-block">
          <div class="time-picker-sm-area">
            <div class="time-picker-only">
              <flat-pickr
                v-model="range.start"
                class="form-control"
                :config="dateConfigSm"
              />
            </div>

            <div class="mx-50">
              ~
            </div>

            <div class="time-picker-only">
              <flat-pickr
                v-model="range.end"
                class="form-control"
                :config="dateConfigSm"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="loading-area"
    >
      <b-img
        src="/admin/images/common/loading-2.png"
        rounded
        height="60"
        width="60"
      />
    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import language from 'flatpickr/dist/l10n/zh-tw'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BImg,
    flatPickr,
  },
  props: {
    dateRangeCustomizeId: { // (唯一)值
      type: String,
      required: true,
    },
    useTime: { // (是否)使用時間
      type: Boolean,
      default: false,
    },
    initData: { // (初始化)資料
      type: String || null,
      default: null,
    },
    minDateTime: { // (最小)日期鎖定
      type: String || Date,
      default: null,
    },
    maxDateTime: { // (最大)日期鎖定
      type: String || Date,
      default: null,
    },
  },
  data() {
    return {
      range: {
        start: new Date(),
        end: new Date(),
      },
      masksDate: {
        input: 'YYYY-MM-DD',
      },
      masksTime: {
        input: 'YYYY-MM-DD h:mm',
      },
      state: false,
      minDate: null,
      maxDate: null,
    }
  },
  watch: {
    range: {
      handler(newRange) {
        this.inputDateTime(`${newRange.start} 至 ${newRange.end}`)
      },
      deep: true,
    },
  },
  methods: {
    // (跳轉至)指定日期
    specifiedTime(start, end) {
      let startDate = start instanceof Date ? new Date(start.getTime()) : new Date()
      let endDate = end instanceof Date ? new Date(end.getTime()) : new Date()

      if (this.maxDateTime && endDate > new Date(this.maxDateTime)) {
        endDate = new Date(this.maxDateTime)
      }

      if (this.minDateTime && startDate < new Date(this.minDateTime)) {
        startDate = new Date(this.minDateTime)
      }

      // Format dates
      startDate = this.formatDateTime(startDate)
      endDate = this.formatDateTime(endDate)

      this.range = {
        ...this.range,
        start: startDate,
        end: endDate,
      }
    },

    // (跳轉至)指定日期
    quickTime(time, unit) {
      const timeInterval = unit === 'h' ? time * 60 * 60 * 1000 : time * 24 * 60 * 60 * 1000
      const currentDate = new Date()

      let startDate = new Date()
      let endDate = new Date()

      if (time >= 0) {
        endDate = this.formatDateTime(new Date(currentDate.getTime() + timeInterval))
        startDate = this.formatDateTime(currentDate)
      } else {
        startDate = this.formatDateTime(new Date(currentDate.getTime() + timeInterval))
        endDate = this.formatDateTime(currentDate)
      }

      this.range = {
        ...this.range,
        start: startDate,
        end: endDate,
      }
    },

    // (標轉化)日期時間
    formatDateTime(inputDate) {
      const date = new Date(inputDate)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')

      if (this.useTime) return `${year}-${month}-${day} ${hours}:${minutes}`
      return `${year}-${month}-${day}`
    },

    inputDateTime(time) {
      let resolveTimeRange = null
      resolveTimeRange = time.split(' 至 ')
      const [start, end] = resolveTimeRange
      const formattedStart = this.formatDateTime(start)
      if (end) {
        const formattedEnd = this.formatDateTime(end)
        resolveTimeRange = `${formattedStart} to ${formattedEnd}`
      } else resolveTimeRange = `${formattedStart}`
      this.$emit('call-back-time', resolveTimeRange)
    },

    // (初始化)日期時間
    initDataTime() {
      this.state = false
      const dateRangeString = this.initData
      if (!dateRangeString) {
        this.minDate = this.minDateTime
        this.maxDate = this.maxDateTime
        this.state = true
        return
      }

      const [startString, endString] = dateRangeString.split(' to ')

      const startDate = this.formatDateTime(startString)
      const endDate = this.formatDateTime(endString)

      this.range = {
        ...this.range,
        start: startDate,
        end: endDate,
      }
      this.minDate = this.minDateTime
      this.maxDate = this.maxDateTime
      this.state = true
    },
  },
  setup() {
    const dateConfigLg = {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'Y-m-d H:i',
      inline: true,
      locale: language.zh_tw,
    }

    const dateConfigSm = {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'Y-m-d H:i',
      // inline: true,
      locale: language.zh_tw,
    }

    return {
      dateConfigLg,
      dateConfigSm,
    }
  },
}
</script>

<style lang="scss" scoped>
.time-picker-lg-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .time-picker-only {
    .flatpickr-input {
      display: none;
    }

    .flatpickr-time {
      border-top: none;
    }

    // .flatpickr-calendar {
    //   width: 200px !important;
    // }
  }
}

.time-picker-sm-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loading-area {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

// @media (max-width: 767px) {
//   .time-picker-area {
//     // display: flex;
//     // align-items: center;
//     // justify-content: space-between;
//     flex-direction: column;
//     // .time-picker-only {
//     //   .flatpickr-input {
//     //     display: none;
//     //   }

//     //   .flatpickr-time {
//     //     border-top: none;
//     //   }

//     //   // .flatpickr-calendar {
//     //   //   width: 200px !important;
//     //   // }
//     // }
//   }
// }

// .flatpickr-calendar {
//   width: 100% !important;
//   background-color: yellow;
// }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.time-picker-only {
  .flatpickr-calendar {
    max-width: 200px;
    // box-shadow: none;
    // border-top: none;
    .flatpickr-time {
      border-top: none;
    }
  }
}

</style>
