<template>
  <b-modal
    :id="updateModalId"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        編輯AI角色
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- AI角色名稱 -->
        <validation-provider
          #default="{ errors }"
          name="AI機器人名稱"
          rules="required"
        >
          <b-form-group label-for="role_name">
            <template #label>
              <label class="mb-0">
                AI機器人名稱
                <span class="text-danger">*</span>
              </label>
            </template>

            <b-form-input
              id="role_name"
              v-model="aiData.role_name"
              autofocus
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="aiData.role_name ? aiData.role_name : '請輸入AI機器人名稱'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <div>
          <label>
            AI角色設定
            <span class="text-danger">*</span>
          </label>
          <b-form-textarea
            v-model="aiData.setting"
            :placeholder="aiData.setting ? aiData.setting : '請輸入AI角色設定'"
            :state="aiData.setting.length <= maxChar ? null : false"
            rows="5"
          />
          <small
            class="textarea-counter-value float-right"
            :class="aiData.setting.length > maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ aiData.setting.length }}</span> / {{ maxChar }}
          </small>
        </div>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
// API
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

// UI
import {
  BForm, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BFormGroup, BFormInput, BFormTextarea,
  VBTooltip, // BInputGroupAppend, BInputGroup, BImg,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

// Component
import formValidation from '@core/comp-functions/forms/form-validation'
// import AIExplorerModal from '@/layouts/components/Modal/brand-modal/brandModal.vue'
import { useOpenAIModal, useModalSetting } from '../useModal'
import { useAlert } from '@/libs/mixins/index'

export default {
  components: {
    // BImg,
    BForm,
    // BFormGroup,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    // BInputGroup,
    // BInputGroupAppend,
    // BFormSpinbutton,

    // vSelect,
    ValidationProvider,
    ValidationObserver,
    // AIExplorerModal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    selectedItem: {
      type: Object,
      required: true,
    },
    updateModalId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      missingFields: [],
      maxChar: 255,
    }
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetAIData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide(this.updateModalId)
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      // 判斷必填欄位是否填寫
      const requiredFields = {
        role_name: 'AI角色名稱',
        setting: 'AI角色設定',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.aiData[Object.keys(requiredFields)[i]] === null || this.aiData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            lable: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.aiData.setting.length > this.maxChar) {
        this.useAlertToast(false, 'AI角色設定字數超過上限')
        return
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].lable}不得為空`)
        return
      }

      this.isBusy = true

      if (this.selectedItem.id) {
        this.setOpenAIUpdate({
          brand_id: this.selectedItem.id,
          data: {
            ...this.aiData,
          },
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('reset-table')
            this.resetAIData()
            this.$nextTick(() => {
              this.$bvModal.hide(this.updateModalId)
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      } else {
        this.setOpenAICreate({
          ...this.aiData,
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('reset-table')
            this.resetAIData()
            this.$nextTick(() => {
              this.$bvModal.hide(this.updateModalId)
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      }
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankAIData, this.selectedItem),
      }
      this.aiData = resolveData
      this.$bvModal.show(this.updateModalId)
    },
  },
  setup() {
    localize('tw')

    const {
      setOpenAICreate,
      setOpenAIUpdate,
    } = useOpenAIModal()

    const {
      isBusy,
      syncObject,
    } = useModalSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankAIData = {
      role_name: null,
      setting: '',
    }

    const aiData = ref(JSON.parse(JSON.stringify(blankAIData)))

    const resetAIData = () => {
      aiData.value = JSON.parse(JSON.stringify(blankAIData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetAIData)

    return {
      isBusy,

      aiData,
      blankAIData,
      setOpenAICreate,
      setOpenAIUpdate,

      resetAIData,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>
