<template>
  <div
    class="chat-app"
    style="height: inherit"
  >
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar=false"
    />
    <section class="chat-app-window">
      <!-- 開始新增聊天室 -->
      <div
        v-show="!activeInfo.data"
        class="start-chat-area"
      >
        <div class="start-chat-label">
          <div
            class="mb-1 start-chat-icon"
            @click="startConversation"
          >
            <feather-icon
              icon="MessageSquareIcon"
              size="56"
            />
          </div>

          <div class="quick-area mb-25">
            <h4
              class="sidebar-toggle start-chat-text mx-50"
              @click="openChatRoom"
            >
              開始對話
            </h4>
          </div>
        </div>
        <div
          class="quick-create-area"
          @click="openChatRoom"
        >
          <role-modal
            ref="roleModal"
            brand-explorer-id="openAISelectModal"
            :is-hiden-emit="false"
            :use-callback-data="true"
            @call-back-data="openAISelected"
          />
        </div>
      </div>

      <!-- 訊息紀錄 -->
      <div
        v-if="activeInfo.data"
        class="active-chat animate__animated animate__fadeIn"
      >
        <div class="chat-navbar">
          <div v-if="!searchMode">
            <header class="chat-header">

              <div class="d-flex align-items-center">

                <div class="sidebar-toggle d-block d-lg-none mr-1">
                  <feather-icon
                    icon="MenuIcon"
                    class="cursor-pointer"
                    size="21"
                    @click="mqShallShowLeftSidebar = true"
                  />
                </div>

                <div class="go-back mr-1 d-none d-lg-block">
                  <feather-icon
                    :icon="'ChevronLeftIcon'"
                    size="20"
                    class="align-bottom cursor-pointer"
                    @click="() => {
                      activeInfo = {}
                      $router.push({ name: 'admin-openAI-room' })
                    }"
                  />
                </div>
                <h5
                  class="mb-0 mt-25 email-subject cursor-pointer"
                  @click="submitRenameOpenAIRoom(activeInfo.data.id, activeInfo.data.room_name)"
                >
                  <span v-if="activeInfo.data.status === 'creating'">
                    目前選擇的AI是
                  </span>
                  {{ activeInfo.data.room_name }}
                  <!-- / {{ activeInfo.data.status }} -->
                </h5>
              </div>

              <div class="d-flex align-items-center">
                <div
                  v-b-tooltip.hover.v-secondary
                  title="搜尋"
                  class="actions-link-btn-sm"
                  @click="openInputSearch"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="17"
                    class="align-middle text-body"
                  />
                </div>

                <div class="dropdown">
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <div
                        v-b-tooltip.hover.v-secondary
                        title="更多"
                        class="actions-link-btn-sm"
                      >
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="17"
                          class="align-middle text-body"
                        />
                      </div>
                    </template>

                    <b-dropdown-item
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click="submitRenameOpenAIRoom(activeInfo.data.id, activeInfo.data.room_name)"
                    >
                      <b-img
                        src="/admin/images/table/edit.svg"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>重新命名</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click="submitShareOpenAIRoom"
                    >
                      <b-img
                        src="/admin/images/todo/action/share.svg"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>分享連結</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      class="dropdown-item-area animate__animated animate__fadeIn"
                      @click="submitDeleteOpenAIRoom([activeInfo.data.id])"
                    >
                      <b-img
                        src="/admin/images/todo/action/trash-bin.svg"
                        class="dropdown-item-btn-image mr-50"
                        rounded
                      />
                      <span>刪除</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>

              <div
                class="chat-header-gotop"
                @click="scrollToTopInChatLog"
              >
                返回頂部
              </div>
            </header>
          </div>

          <div v-else>
            <header class="chat-header">

              <div class="d-flex align-items-center w-100">
                <b-input-group class="input-group-merge w-100">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    ref="inputSearch"
                    v-model="searchQuery"
                    placeholder="搜尋..."
                    debounce="500"
                    @input="inputMessageSearch"
                  />
                  <b-input-group-append is-text>
                    <small class="text-muted">{{ searchIndex }} / {{ searchResults.length }}</small>
                  </b-input-group-append>
                </b-input-group>
              </div>

              <div class="text-nowrap d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <div
                    v-b-tooltip.hover.v-secondary
                    title="下一個結果"
                    class="actions-link-btn-sm ml-50"
                    @click="searchNextReult('next')"
                  >
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="24"
                      class="actions-link-btn-image"
                    />
                  </div>

                  <div
                    v-b-tooltip.hover.v-secondary
                    title="上一個結果"
                    class="actions-link-btn-sm ml-50"
                    @click="searchNextReult('pre')"
                  >
                    <feather-icon
                      icon="ChevronUpIcon"
                      size="24"
                      class="actions-link-btn-image"
                    />
                  </div>

                  <div
                    v-b-tooltip.hover.v-secondary
                    title="取消"
                    class="actions-link-btn-sm ml-50"
                    @click="cancelSearchReult"
                  >
                    <feather-icon
                      icon="XIcon"
                      size="24"
                      class="actions-link-btn-image"
                    />
                  </div>
                </div>
              </div>
            </header>
          </div>
        </div>

        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats user-chats-message"
          @ps-scroll-y="chatScrollHandle"
        >
          <!-- {{ activeInfo }} -->
          <room-log
            ref="refChatLog"
            :room-info="activeInfo"
            :room-message="activeMessage"
            :room-input="chatInputMessage"
            :profile-user-data="profileUserData"
            :is-message-loading="activeMessageBusy"
            :search-point="searchPoint"
          />
          <!-- {{ activeMessage }} -->
        </vue-perfect-scrollbar>

        <div
          class="go-bottom"
          :class="{ 'go-bottom-show' : !isChatLogAtBottom}"
        >
          <div
            class="go-bottom-btn chatLog"
            @click="scrollToBottomInChatLog"
          >
            <feather-icon
              icon="ArrowDownIcon"
              size="24"
              class="gobottom-btn-icon"
            />
          </div>
        </div>

        <b-form
          class="chat-app-form"
          @submit.prevent
        >
          <!-- randomIdArray: {{ randomIdArray }} -->

          <div class="chat-app-form-action">
            <label
              class="chat-app-form-icon mb-1"
              @click="submitUploadFile()"
            >
              <feather-icon
                icon="LinkIcon"
                size="20"
                class="append-icon"
              />
              <!-- <input
                ref="fileInput"
                type="file"
                multiple
                hidden
                @input="submitUploadFile($event)"
              > -->
            </label>

            <div class="chat-app-form-icon mb-75">
              <my-emoji-component
                @select-emoji="selectEmojiIcon"
              >
                <label
                  slot="button-content"
                  class="cursor-pointer"
                >
                  <feather-icon
                    icon="SmileIcon"
                    size="20"
                    class="emoji-icon"
                  />
                </label>
              </my-emoji-component>
            </div>

            <div class="w-100 mr-1">
              <textarea
                ref="chatInputMessageTextarea"
                v-model="chatInputMessage"
                class="w-100 site-input-area"
                :style="{
                  'min-height': '40px',
                  'color': !isDark ? '#000000' : '#d0d2d6',
                  'border-color': !isDark ? '#d0d2d6' : '#4b5c7c',
                }"
                :disabled="activeInfo.data.status && activeInfo.data.status === 'waitResponse'"
                placeholder="輸入備註..."
                @paste="handlePaste"
                @keydown="handleKeyDown"
              />
            </div>

            <div
              class="chat-app-form-icon mb-1"
              @click="sendMessage"
            >
              <feather-icon
                icon="SendIcon"
                size="20"
                class="send-icon text-primary"
              />
            </div>
          </div>
        </b-form>
      </div>
    </section>

    <portal to="content-renderer-sidebar-left">
      <open-ai-left-sidebar
        ref="openAILeftSidebar"
        :active-chat-contact-id="activeInfo.data ? activeInfo.data.id : null"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @clear-active-info-data="clearActiveInfoData"
        @open-openAI-add-sidebar="openChatRoom"
        @open-chat="openChatOfContact"
        @rename-openAI-room="submitRenameOpenAIRoom"
      />
    </portal>

    <rename-modal
      ref="renameModal"
      @refetch-data="refreshRename"
    />
  </div>
</template>

<script>
import {
  ref, onUnmounted, nextTick, computed,
} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BDropdown, BDropdownItem, BForm, VBTooltip, BImg, BInputGroup, BFormInput,
  BInputGroupPrepend, BInputGroupAppend,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import OpenAiLeftSidebar from './OpenAILeftSidebar.vue'
import useStoreModule from '../useStoreModule'
import { useOpenAISetting, useRoomMessage } from '../useOpenAI'
import RoomLog from './OpenAIRoomLog.vue'
import RoleModal from '@/layouts/components/Modal/openAI-modal/aiModal.vue'
import RenameModal from './OpenAIRenameModal.vue'
import store from '@/store'
import router from '@/router'
import { useAlert, useSwalToast } from '@/libs/mixins/index'
import { blankSocketData } from '@/libs/socket-client'
import MyEmojiComponent from '@/layouts/components/EmojiPicker/EmojiPicker.vue'

export default {
  components: {
    // BButton,
    BDropdown,
    BDropdownItem,
    BForm,
    // BModal,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BImg,

    VuePerfectScrollbar,

    OpenAiLeftSidebar,
    RoomLog,
    RoleModal,
    RenameModal,
    MyEmojiComponent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      searchMode: false,
      searchIndex: 0,
      searchResults: [],
      searchPoint: null,
      isChatLogAtBottom: false,
    }
  },
  computed: {
    isSocketInit() {
      // console.log(this.$store.state.app.isSocketInitialized)
      return this.$store.state.app.isSocketInitialized
    },
    // currentMessageFilter() {
    //   return this.activeMessage
    // },
  },
  watch: {
    isSocketInit(newValue) {
      if (newValue) {
        this.resetSocketConnect()
      }
    },
  },
  mounted() {
    this.initSocketFunction()
    this.$store.dispatch('app/isSocketFunctionExist', 'joinOpenAI')
      .then(state => {
        if (state) window.socket.joinOpenAI()
      })
    if (this.currentId) {
      this.activeMessageBusy = true
      this.refreshMessageList(this.currentId)
    }
  },
  beforeDestroy() {
    const { blankOpenAIRoom, blankUsers } = blankSocketData()
    window.web.openAI = { ...blankOpenAIRoom }
    window.web.users = { ...blankUsers }
  },
  methods: {
    // (顯示)檔案字數(?)
    resolveFileNameLong(text, length) {
      if (text.length > length * 2) {
        const start = text.slice(0, length)
        const end = text.slice(-length)
        return `${start}...${end}`
      }
      return text
    },

    // (判斷顯示模式)(?)
    resolveMegType(msg, appends, images) {
      if (msg) {
        return this.resolveFileNameLong(msg, 20)
      }

      if (!msg) {
        if (images) return '回覆一則圖片訊息'
        if (appends) return '回覆一則檔案訊息'
      }
      return null
    },

    // --------------------------------------搜尋-----------------------------------------------
    // (打開)輸入搜尋
    openInputSearch() {
      this.searchMode = true
      setTimeout(() => { this.$refs.inputSearch.focus() }, 200)
    },

    // (輸入)搜尋結果
    inputMessageSearch() {
      clearTimeout(this.timeSearch)
      this.timeSearch = setTimeout(() => {
        if (!this.searchQuery) {
          this.searchIndex = 0
          this.searchResults = []
          this.scrollToBottomInChatLog()
          return
        }

        const searchKeyword = this.searchQuery ? this.searchQuery.toLowerCase() : this.searchQuery
        if (searchKeyword) {
          const messageArray = this.activeMessage.filter(f => !f.message || f.message.includes(searchKeyword.toLowerCase()))
          // .map(item => item.id)
          // console.log(messageArray.map(item => item.id))
          if (messageArray.length > 0) {
            this.searchResults = messageArray.reverse()
            this.searchIndex = messageArray.length
            this.searchPoint = messageArray[messageArray.length - 1].id
            this.scrollToElement(messageArray[messageArray.length - 1].id)
          } else {
            this.searchIndex = 0
            this.searchResults = []
          }
        }
      }, 1000)
    },

    // (搜尋)下一個結果
    searchNextReult(type) {
      const resultsNum = this.searchResults.length
      if (resultsNum === 0) return

      let newIndex = this.searchIndex

      // 计算新的索引位置
      if (type === 'next') {
        newIndex = (this.searchIndex + 1) % (resultsNum + 1)
      } else if (type === 'pre') {
        newIndex = ((this.searchIndex - 2 + resultsNum) % resultsNum) + 1
      }
      this.searchIndex = newIndex <= 0 ? 1 : newIndex

      const newData = parseInt(JSON.parse(JSON.stringify(this.searchIndex)), 10) - 1
      const nextId = this.searchResults[newData].id
      // console.log(this.searchPoint, nextId)
      this.searchPoint = nextId
      this.scrollToElement(nextId)
    },

    // (移動至)搜尋結果
    scrollToElement(id) {
      const container = document.querySelector('.user-chats')
      const child = document.getElementById(`message-${id}`)

      if (container && child) {
        container.scrollTop = child.offsetTop - container.offsetTop
        // child.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    },

    // (取消)搜尋結果
    cancelSearchReult() {
      this.searchIndex = 0
      this.searchQuery = null
      this.searchMode = false
      this.searchResults = []
      this.searchPoint = null
      if (this.activeInfo.data) setTimeout(() => { this.$refs.chatInputMessageTextarea.focus() }, 200)
    },

    // (滾軸)滾動監測
    chatScrollHandle() {
      if (this.$refs.refChatLogPS && this.$refs.refChatLog) {
        if (this.$refs.refChatLog.closeAction()) this.$refs.refChatLog.closeAction()
        const scrollChatLogEl = this.$refs.refChatLogPS.$el
        const { scrollTop, clientHeight, scrollHeight } = scrollChatLogEl
        this.isChatLogAtBottom = scrollTop + clientHeight >= scrollHeight
      }
    },

    // -----------------------------------訊息--------------------------------------------------
    // (移除)訊息
    // removeMessageIndex(index) {
    //   // this.activeInfo.data.messages.splice(index, 1)
    //   this.activeMessage.splice(index, 1)
    //   this.scrollToBottomInChatLog()
    // },

    // (調整)訊息檔案輸入欄位
    adjustTextareaHeight() {
      if (!this.$refs.chatInputMessageTextarea) return
      this.$nextTick(() => {
        const textarea = this.$refs.chatInputMessageTextarea
        textarea.style.height = '40px'
        if (textarea.scrollHeight < 100) {
          textarea.style.height = `${textarea.scrollHeight}px`
        } else {
          textarea.style.height = '100px'
        }
      })
    },

    // (觸發)檔案上傳
    submitUploadFile() { // event
      if (!navigator.clipboard) {
        this.useAlertToast(false, '暫不支援該功能')
      }
      // if (event.target.files.length === 0) return
      // this.$refs.refChatLog.importFile(event.target)
      // this.$refs.fileInput.value = null
    },

    // (觸發)輸入欄位按鈕
    handleKeyDown(event) { // event
      // if (this.chatInputMessage === '' && (event.key === 'ArrowUp' || event.keyCode === 38)) {
      //   if (!this.profileUserData.id) return
      //   const filteredMessages = this.activeMessage.filter(user => user.user_id === this.profileUserData.id)
      //   if (filteredMessages.length <= 0) return
      //   // const lastMessage = filteredMessages.pop()
      //   // this.$refs.refChatLog.submitEditMessage(lastMessage)
      // }
      if (event.shiftKey && event.key === 'Enter') {
        const cursorPosition = event.target.selectionStart
        this.chatInputMessage = `${this.chatInputMessage.slice(0, cursorPosition)}\n${this.chatInputMessage.slice(cursorPosition)}`
        event.preventDefault()
        const newCursorPosition = cursorPosition + 1
        this.$nextTick(() => {
          this.$refs.chatInputMessageTextarea.setSelectionRange(newCursorPosition, newCursorPosition)
          this.adjustTextareaHeight()
        })
      }
      if (event.ctrlKey && event.key === 'Enter') {
        const cursorPosition = event.target.selectionStart
        this.chatInputMessage = `${this.chatInputMessage.slice(0, cursorPosition)}\n${this.chatInputMessage.slice(cursorPosition)}`
        event.preventDefault()
        const newCursorPosition = cursorPosition + 1
        this.$nextTick(() => {
          this.$refs.chatInputMessageTextarea.setSelectionRange(newCursorPosition, newCursorPosition)
          this.adjustTextareaHeight()
        })
      }
      if (!event.shiftKey && event.key === 'Enter') {
        event.preventDefault()
        this.sendMessage()
      }
    },

    // (按下)Ctrl + V
    handlePaste() {
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      navigator.clipboard.read()
        .then(clipboardItems => {
          clipboardItems.forEach(clipboardItem => {
            const { types } = clipboardItem
            if (types.length === 1) {
              const type = types[0]
              if (type === 'image/png' || type === 'image/jpeg') {
                clipboardItem.getType(type).then(blob => {
                  const file = new File([blob], 'pasted_image.png', { type: blob.type })
                  this.$refs.refChatLog.importFile({ files: [file] })
                })
              }
            }
            if (types.length === 2) {
              if (types.includes('text/html') && types.includes('image/png')) {
                const type = types[1]
                clipboardItem.getType(type).then(blob => {
                  const file = new File([blob], 'pasted_html_image.png', { type: blob.type })
                  this.$refs.refChatLog.importFile({ files: [file] })
                })
              }
            }
          })
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },

    // (新增)EMOJI
    selectEmojiIcon(emoji) {
      const textareaRef = this.$refs.chatInputMessageTextarea
      const cursorPosition = textareaRef.selectionStart
      this.chatInputMessage = `${this.chatInputMessage.slice(0, cursorPosition)}${emoji}${this.chatInputMessage.slice(cursorPosition)}`
      const newCursorPosition = cursorPosition + emoji.length
      this.$nextTick(() => {
        textareaRef.setSelectionRange(newCursorPosition, newCursorPosition)
      })
    },

    // (更新)忙碌狀態(O)
    updateOpenAIRoomBusy(state) {
      this.activeMessageBusy = state
    },

    // (刪除)聊天室(O)
    submitDeleteOpenAIRoom(roomIdArr) {
      if (roomIdArr.length <= 0) return
      this.useSwalAlertWarning('刪除聊天室', `你確定要永久刪除聊天室 ${this.activeInfo.data.room_name ? this.activeInfo.data.room_name : '未命名聊天室'} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setOpenAIRoomDelete({
              data: {
                room_id: roomIdArr,
              },
            })
              .then(() => {
                if (roomIdArr.include(this.activeInfo.data.id)) this.clearActiveInfoData()
                this.refreshOpenAIRoomList()
                this.useSwalAlertCenter(true, '刪除成功!', '聊天室已移除')
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },

    // (清除)當前
    clearActiveInfoData() {
      this.activeInfo = {
        data: null,
      }
      this.$router.push({ name: 'admin-openAI-room' })
      this.activeMessageBusy = false
    },

    // -------------------------------------------------------------------------------------
    // (開啟)快速新增列表
    // openOpenAIRoomQuickListModal() {
    //   this.$refs.roomQuickList.handleShowListModal()
    // },

    // (觸發)分享(O)
    submitShareOpenAIRoom() {
      if (!this.activeInfo.data) return
      if (!navigator.share) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      const url = `/admin/openAI/room?fid=${this.activeInfo.data.id}`
      navigator.share({
        title: '曠世國際管理後台',
        text: '我在AI聊天室列表找到一個有趣的內容，趕緊來看看吧',
        url,
      })
    },

    // (觸發)重新命名
    submitRenameOpenAIRoom(id, name) {
      setTimeout(() => { this.$refs.renameModal.getData({ id, name }) }, 200)
    },

    // --------------------------------------重整------------------------------------------
    // 獲取聊天室詳情
    openChatOfContact(roomId, room) {
      // console.log(roomId, room)
      if (room === undefined && this.activeInfo.state !== undefined && this.activeInfo.state === false) return

      if (room) {
        this.activeInfo = {
          data: {
            ...room,
          },
          state: false,
        }
        if (this.$refs.refChatLog) this.$refs.refChatLog.editCol = ''
      }
      this.chatInputMessage = ''

      // (重整)訊息
      if (this.activeMessageBusy) return
      this.activeMessageBusy = true
      this.cancelSearchReult()
      this.refreshMessageList(roomId)

      this.$store.dispatch('app/isSocketFunctionExist', 'joinOpenAI')
        .then(state => {
          if (state) window.socket.joinOpenAI(roomId) // resolveResponse.id
        })
      if (this.activeInfo.data) setTimeout(() => { this.$refs.chatInputMessageTextarea.focus() }, 200)
    },

    // (重整)更新聊天室
    // updateRefresh(roomId) {
    //   this.refreshOpenAIRoomList()
    //   this.refreshMessageList(roomId)
    // },

    // (重整)聊天室列表
    refreshOpenAIRoomList() {
      // console.log('(重整)聊天室列表')
      this.$refs.openAILeftSidebar.refreshOpenAIRoomListPageData()
    },

    // (重整)訊息列表
    refreshMessageList(roomId) {
      this.getOpenAIMessage({
        room_id: roomId,
      })
        .then(async response => {
          const { room_data, userData } = response.data.data
          this.activeInfo = {
            ...this.resolveRoomInfoData(room_data),
            state: true,
          }

          this.activeMessage = await this.resolveMessageListData(response)

          this.profileUserData = {
            id: userData ? userData.id : null,
            name: userData ? userData.name : null,
            image: userData ? userData.image : null,
            online_status: 'online',
          }

          nextTick(() => {
            this.activeMessageBusy = false
            const targetRoute = { name: 'admin-openAI-room-view', params: { id: this.activeInfo.data.id } }
            if (parseInt(this.$route.params.id, 10) !== this.activeInfo.data.id) {
              this.$router.push(targetRoute)
            }
            setTimeout(() => this.scrollToBottomInChatLog(), 200)
          })
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            this.activeMessage = []
            this.activeMessageBusy = false
            return
          }

          this.activeInfo = {}
          this.activeMessage = []
          this.activeMessageBusy = false
          this.$router.push({ name: 'admin-openAI-room' })

          if (error.response && error.response.status === 404) {
            this.useAlertToast(false, error.response.data.message)
            return
          }
          this.useHttpCodeAlert(error.response)
        })
      this.mqShallShowLeftSidebar = false
    },

    // (重整)修改名稱後(O)
    refreshRename(item) {
      if (this.activeInfo.data && this.activeInfo.data.id === item.id) {
        this.activeInfo.data.room_name = item.name
        this.refreshOpenAIRoomList()
      }
    },

    // (新增)訊息
    createMessageData(message) {
      const resolveData = this.resolveMessageInfoData({
        id: message.logId,
        ...message,
      })
      this.activeMessageBusy = false
      const hasId = this.activeMessage.some(item => item.id === resolveData.id)
      if (!hasId) this.activeMessage.push(resolveData)
      this.scrollToBottomInChatLog()
    },

    // -------------------------------------新增聊天室------------------------------------------------
    // 新增聊天室(O)
    openChatRoom() {
      this.$refs.roleModal.getData()
    },

    // (新增)AI選擇
    openAISelected(roleData) {
      this.activeInfo = {
        data: {
          ...this.syncObject(this.blankRoomData, {
            role_id: roleData.id,
            room_name: roleData.role_name,
            status: 'creating',
          }),
          role_info: roleData,
        },
      }
      this.activeMessage = []
    },

    // --------------------------------------Socket------------------------------------------
    // (初始化)Socket
    initSocketFunction() {
      const windowFunction = window.web
      windowFunction.openAI = {
        refreshOpenAIRoleList: () => {
          this.$refs.roleModal.refreshData()
        },
        refreshOpenAIRoomList: () => {
          this.refreshOpenAIRoomList()
        },
        refreshOpenAIChat: message => {
          // console.log('chatGpt response:', message)
          if (!message) return

          if (this.activeInfo.data && this.activeInfo.data.id === message.room_id) {
            this.createMessageData(message)
            this.activeInfo.data.status = 'normal'
          }
          this.refreshOpenAIRoomList()
        },
      }
      windowFunction.users = {
        refreshUserOnlineStatus: (id, status) => {
          const updateMessage = this.activeMessage.map(message => {
            if (message.user_id === id) {
              return {
                ...message,
                user: {
                  ...message.user,
                  online_status: status,
                },
              }
            }
            return message
          })
          this.activeMessage = [...updateMessage]
        },
        refreshUsersList: () => {},
      }
      if (this.isSocketInit) store.commit('app/SET_SOCKET_INIT', false)
    },

    // (重建)Socket連線
    resetSocketConnect() {
      if (this.activeInfo.data) {
        this.$store.dispatch('app/isSocketFunctionExist', 'joinOpenAI')
          .then(state => {
            if (state) window.socket.joinOpenAI()
          })
      }
      store.commit('app/SET_SOCKET_INIT', false)
    },
  },
  setup() {
    // 註冊模組
    const USER_ADMIN_STORE_MODULE_NAME = 'admin-openAI'

    if (!store.hasModule(USER_ADMIN_STORE_MODULE_NAME)) store.registerModule(USER_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(USER_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(USER_ADMIN_STORE_MODULE_NAME)
    })

    const currentId = router.currentRoute.params.id
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const refChatLog = ref(null)
    const chatInputMessageTextarea = ref(null)
    const openAILeftSidebar = ref(null)
    const searchQuery = ref(null)

    const activeInfo = ref({})
    const activeMessage = ref([])
    const activeMessageBusy = ref(false)
    const profileUserData = ref({})

    const chatInputMessage = ref('')

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const {
      syncObject,
    } = useOpenAISetting()

    const {
      blankRoomData,
      blankMessageData,
      resolveRoomInfoData,
      resolveMessageInfoData,
      resolveMessageListData,

      getOpenAIMessage,
      setOpenAIRoomCreate,
      setOpenAIMessageCreate,

      generateRandomString,
      removeRandomIdArray,
    } = useRoomMessage()

    const scrollToBottomInChatLog = () => {
      if (!activeInfo.value.data) return
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
      if (scrollEl) {
        const options = {
          behavior: 'smooth',
          block: 'end',
          inline: 'end',
        }
        scrollEl.scrollIntoView(options)
      }
    }

    const scrollToTopInChatLog = () => {
      if (!activeInfo.value.data) return
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = 0
      if (scrollEl) {
        const options = {
          behavior: 'smooth',
          block: 'end',
          inline: 'end',
        }
        scrollEl.scrollIntoView(options)
      }
    }

    // (送出)文字預覽(V)
    const sendMessage = () => {
      // waitResponse / normal
      if (!chatInputMessage.value) return
      if (activeMessageBusy.value) return

      if (activeInfo.value && activeInfo.value.data) {
        if (activeInfo.value.data.status === 'waitResponse') return
        activeInfo.value.data.status = 'waitResponse'
      } else return

      const rId = generateRandomString()

      const resolveData = resolveMessageInfoData({
        rid: rId,
        role: 'user',
        // room_id: activeInfo.value.data.id,
        message: chatInputMessage.value,
        user_id: profileUserData.value.id ? profileUserData.value.id : null,
        user: profileUserData.value,
        created_at: new Date(),
      })

      activeMessage.value.push(resolveData)

      // 檢查room_id是否存在
      if (activeInfo.value && !activeInfo.value.data.id) {
        const payload = {
          role_id: activeInfo.value.data.role_id,
          message: chatInputMessage.value,
        }
        setOpenAIRoomCreate(payload)
          .then(async response => {
            const { room_data } = response.data.data // logs,

            activeInfo.value = {
              ...resolveRoomInfoData(room_data),
            }
            activeMessage.value = await resolveMessageListData(response)

            nextTick(() => {
              chatInputMessage.value = ''
              chatInputMessageTextarea.value.focus()
              chatInputMessageTextarea.value.style.height = '40px'
              openAILeftSidebar.value.refreshOpenAIRoomListPageData()
              const targetRoute = { name: 'admin-openAI-room-view', params: { id: room_data.id } }
              if (router.name !== targetRoute.name || router.params.id !== targetRoute.params.id) {
                router.push(targetRoute)
              }
            })
          })
          .catch(error => {
            activeInfo.value.data.status = 'normal'
            router.push({ name: 'admin-openAI-room' })
            useHttpCodeAlert(error.response)
          })
      } else {
        const payload = {
          room_id: activeInfo.value.data.id,
          message: chatInputMessage.value,
        }
        setOpenAIMessageCreate(payload)
          .then(response => {
            const { data } = response.data
            const resolveResponse = resolveMessageInfoData({ ...data })
            resolveResponse.user = {
              ...profileUserData.value,
            }
            const index = activeMessage.value.findIndex(item => item.rid === rId)
            if (index !== -1) {
              activeMessage.value.splice(index, 1, resolveResponse)
              removeRandomIdArray(rId)
            }

            nextTick(() => {
              scrollToBottomInChatLog()
              chatInputMessage.value = ''
              chatInputMessageTextarea.value.focus()
              chatInputMessageTextarea.value.style.height = '40px'
              openAILeftSidebar.value.refreshOpenAIRoomListPageData()
            })
          })
          .catch(error => {
            const index = activeMessage.value.findIndex(item => item.rid === rId)
            if (index !== -1) {
              activeMessage.value.splice(index, 1)
              removeRandomIdArray(rId)
            }
            activeInfo.value.data.status = 'normal'
            useHttpCodeAlert(error.response)
          })
      }
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }
    // ----------------------------------------------------------------------------------------------

    return {
      isDark,

      syncObject,
      blankRoomData,
      blankMessageData,

      currentId,
      refChatLog,
      refChatLogPS,
      openAILeftSidebar,
      chatInputMessageTextarea,
      searchQuery,

      activeInfo,
      activeMessage,
      activeMessageBusy,

      profileUserData,
      chatInputMessage,
      sendMessage,

      // -------------------
      setOpenAIRoomCreate,
      setOpenAIMessageCreate,
      getOpenAIMessage,
      resolveRoomInfoData,
      resolveMessageInfoData,
      resolveMessageListData,

      // UI
      perfectScrollbarSettings,
      scrollToBottomInChatLog,
      scrollToTopInChatLog,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,

      useAlertToast,
      useHttpCodeAlert,

      generateRandomString,
      removeRandomIdArray,
    }
  },
}
</script>

<style lang="scss">
.dark-layout {
  .content-area-wrapper{
    .vue-country-select{
      .country-name {
        color: #e4e5eb;
      }
      .dropdown:hover, .dropdown.open {
        background-color: #1b2337;
      }
      .dropdown-container {
        background-color: #344464;
      }
      .dropdown-search-input {
        background-color: #344464;
        color: #e4e5eb;
      }
    }
  }
}
.content-area-wrapper {
  .vue-country-select{
    .dropdown-container {
      width: 300px;
    }
  }
}
</style>

<style lang="scss" scoped>
.quick-area {
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.email-subject {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.site-input-area {
  border: none;
  background: transparent;
  border-radius: 0;
  resize: none;
  height: 30px;
  margin-top: 15px;
  color: #d0d2d6;
  padding: 10px;
  border: 1px #d0d2d6 solid;
  border-radius: 5px;
  &:focus {
    box-shadow: none;
    border-color: #719ECE;
  }
  &:focus-visible {
    outline: none;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
}

.chat-app-form-icon {
  word-wrap: nowrap;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  .append-icon {
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }

  .emoji-icon {
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }

  .send-icon {
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }
}

.go-bottom {
  position: relative;
  opacity: 0;
  transition: opacity 0.5s ease;
  .go-bottom-btn {
    background-color: rgba(80, 80, 80, 0.398);
    cursor: pointer;
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    bottom: 10px;
    width: 40px;
    height: 40px;
    &:hover {
      background-color: rgba(119, 119, 119, 0.633);
    }
    .gobottom-btn-icon {
      color: white;
      font-weight: bolder;
    }
  }
}

.go-bottom-show {
  opacity: 1;
}

.chat-header {
  position: relative;
  .chat-header-gotop {
    position: absolute;
    z-index: 3;
    width: 100%;
    // bottom: -100%;
    top: 100%;
    left: 0;
    background-color: rgba(125, 124, 124, 0.105);
    color: rgb(255, 255, 255);
    text-align: center;
    opacity: 0;
    transition: opacity 0.5s ease;
    padding: 5px;
    &:hover {
      cursor: pointer;
      background-color: rgba(80, 80, 80, 0.398);
      opacity: 1;
    }
  }
}

.start-chat-area {
  position: relative;
  .quick-create-area {
    pointer-events: visiblePainted;
    position: absolute;
    background-color: #24c8ff1c;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .quick-create-icon {
    position: absolute;
    cursor: pointer;
    bottom: 10px;
    right: 10px;
    .icon-link-btn {
      .icon-link-btn-image {
        height: 40px;
        width: 40px;
        &:hover {
          animation: rotateAnimation 3s linear infinite;
        }
      }
    }
  }

  .start-chat-label {
    // background-color: red;
    // pointer-events: visiblePainted;
    position: absolute;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .start-chat-text {
      &:hover {
        background-color: #419fd9 !important;
        color: white !important;
      }
    }
  }
}

.dropdown-item-area {
  transition: transform ease-out 200ms;
  .dropdown-item-btn-image {
    width: 14px;
    height: 14px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-room-dropdown .8s linear infinite;
    }
  }
}

@keyframes btn-breathe-room-dropdown {
  0%{ transform: scale(0.8); }
  50%{ transform: scale(1.2); }
  100%{ transform: scale(0.8); }
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/my/app-chat.scss";
@import "~@core/scss/base/my/app-open-ai.scss";
</style>
