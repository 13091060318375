<template>
  <component
    :is="tag"
    v-on="$listeners"
    @click="$emit('submit-show-content', roomData.id)"
  >
    <!-- d-flex align-items-center -->
    <!-- py-75 px-1 -->

    <slot name="contextmenu" />
    <slot name="checkbox" />
    <slot
      name="mask"
      class="chat-contact-mask"
    />

    <div>
      <b-avatar
        size="42"
        class="badge-minimal border mr-75"
        :src="`${roomData.role_info.image}`"
        variant="light-secondary"
      />
    </div>

    <div class="d-flex align-self-start w-100 justify-content-between">
      <div
        class="chat-info flex-grow-1 pl-0"
        @click="$emit('submit-show-content', roomData.id)"
      >
        <div
          class="text-truncate"
          :class="{'text-primary': isChatContact }"
        >
          <small v-if="roomData.role_id">
            {{ roomData.role_info.role_name }}
          </small>
          <small v-else>
            尚未設定
          </small>
        </div>

        <h5 class="text-truncate mb-75">
          <!-- {{ roomData.status }} / -->
          {{ roomData.room_name }}
        </h5>

      </div>

      <!-- 時間 -->
      <div class="chat-meta text-nowrap">
        <small class="float-right my-25 chat-time">
          <span v-if="roomData.updated_at">
            <span
              v-b-tooltip.hover.focus.v-secondary
              :title="`${moment(roomData.updated_at).format('HH:mm')}`"
            >
              {{ updateOnline(roomData.updated_at, refonlineTime) }}
            </span>
          </span>
        </small>

        <div v-if="roomData.status === 'waitResponse'">
          <small class="float-right mr-25">
            <span class="loading-loader" />
          </small>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import moment from 'moment'
import { avatarText } from '@core/utils/filter'
import {
  BBadge, VBTooltip, BImg, BAvatar,
} from 'bootstrap-vue'
import { useOpenAISetting } from '../useOpenAI'

export default {
  components: {
    BImg,
    BBadge,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    roomData: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (處理時間)
    resolveTime(time) {
      if (time) return moment(time).format('YYYY/MM/DD HH:mm')
      return moment().format('YYYY/MM/DD HH:mm')
    },
  },
  setup() {
    const {
      // ui,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useOpenAISetting()

    return {
      refonlineTime,
      onlineTime,
      updateOnline,
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.text-truncate {
  max-width: 180px;
}

.chat-contact-mask {
  // background-color: rgba(108, 196, 255, 0.1);
  position: absolute;
  z-index: 20;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.loading-loader {
  width: 3px;
  height: 8px;
  border-radius: 4px;
  display: block;
  margin: 5px;
  position: relative;
  background: currentColor;
  color: #419fd9;
  box-sizing: border-box;
  animation: animloader 0.3s 0.3s linear infinite alternate;

  &:after, &::before {
    content: '';
    width: 3px;
    height: 8px;
    border-radius: 4px;
    background: currentColor;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    box-sizing: border-box;
    animation: animloader 0.3s  0.45s  linear infinite alternate;
  }

  &::before {
    left: -5px;
    animation-delay: 0s;
  }

  @keyframes animloader {
    0%   { height: 15px}
    100% { height: 4px}
  }
}

@media (max-width: 400px) {
  .text-truncate {
    max-width: 100px;
  }
}
</style>
